import React from 'react';

import { TableRow } from './TableRow';
import { MechanicalWarranty } from '../index';

export default function Table({ data }: { data: MechanicalWarranty[] }) {
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 sm:pl-6 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Núm. póliza
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Precio
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center"
                  >
                    Fecha de emisión
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3">
                    <span className="sr-only">Desplegar</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.length ? (
                  data.map((mw: MechanicalWarranty, i: number) => (
                    <TableRow mw={mw} i={i} />
                  ))
                ) : (
                  <tr>
                    <td className="h-[530px]" colSpan={6}>
                      <div className="h-full w-full flex items-center justify-center flex-col gap-5">
                        <p className="w-2/3 text-center text-md">
                          Vaya, parece que aún no has contratado ninguna
                          garantía mecánica.
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
