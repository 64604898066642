import React from 'react';
import { Link } from 'react-router-dom';

import { MenuItem } from './navigation-items';
import classNames from '../helpers/classNames';

export default function NavigationItem({ item }: Readonly<{ item: MenuItem }>) {
  return (
    <Link
      id={`${item.name.toLowerCase()}-nav-button`}
      key={item.name}
      to={item.href}
      className={classNames(
        item.current
          ? 'bg-gray-200 text-blue-700'
          : 'text-gray-600 hover:bg-gray-200 hover:text-blue-700',
        'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
      )}
    >
      <item.icon
        className={classNames(
          item.current
            ? 'text-blue-700'
            : 'text-gray-600 group-hover:text-blue-700',
          'mr-3 shrink-0 h-6 w-6',
        )}
        aria-hidden="true"
      />
      {item.name}
    </Link>
  );
}
