import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { BadgeCheckIcon, ExclamationIcon } from '@heroicons/react/solid';

import classNames from '../../helpers/classNames';
import { MechanicalWarranty } from '../index';
import { parseDayMonthYear } from '../../helpers/parseDate';
import {
  MechanicalWarrantyInfoType,
  getMechanicalWarranty,
} from '../../services/mechanicalWarraties';
import Spinner from '../../components/Spinner';

type FieldType = {
  label: string;
  displayValue: string;
  value: string | number;
};

const vehicleFields = ({
  policyInfo,
}: MechanicalWarrantyInfoType): FieldType[] => [
  {
    label: 'brand',
    displayValue: 'Marca',
    value: policyInfo.insuredObject.brand,
  },
  {
    label: 'model',
    displayValue: 'Modelo',
    value: policyInfo.insuredObject.model,
  },
  {
    label: 'chassisNumber',
    displayValue: 'Bastidor',
    value: policyInfo.insuredObject.chassisNumber,
  },
  {
    label: 'kilometers',
    displayValue: 'Kilómetros',
    value: `${policyInfo.insuredObject.kilometers} Kms`,
  },
  {
    label: 'engineCapacity',
    displayValue: 'Cilindrada',
    value: `${policyInfo.insuredObject.engineCapacity} cc`,
  },
  {
    label: 'carRegistrationDate',
    displayValue: 'Fecha de matriculación',
    value: parseDayMonthYear(policyInfo.insuredObject.carRegistrationDate),
  },
];

const beneficiaryFields = ({
  policyInfo,
}: MechanicalWarrantyInfoType): FieldType[] => {
  const beneficiary = policyInfo?.persons.find(
    (p) => p.personPolicyType === 'BENEFICIARY',
  );
  return [
    {
      label: 'name',
      displayValue: 'Nombre',
      value: beneficiary.name,
    },
    {
      label: 'surname',
      displayValue: 'Apellidos',
      value: beneficiary.surname,
    },
    {
      label: 'document',
      displayValue: 'DNI',
      value: beneficiary.document,
    },
    {
      label: 'address',
      displayValue: 'Dirección',
      value: `${beneficiary.address}, ${beneficiary.postalCode}, ${beneficiary.town}`,
    },
    {
      label: 'email',
      displayValue: 'Correo',
      value: beneficiary.email,
    },
    {
      label: 'phone',
      displayValue: 'Teléfono',
      value: beneficiary.phoneNumber,
    },
  ];
};

const infoFields = ({
  policyInfo,
}: MechanicalWarrantyInfoType): FieldType[] => [
  {
    label: 'duration',
    displayValue: 'Duración',
    value: `${policyInfo.duration} año${policyInfo.duration === 1 ? '' : 's'}`,
  },
  {
    label: 'paymentStatus',
    displayValue: 'Pagado',
    value:
      policyInfo.paymentInformation?.paymentStatus === 'PAID'
        ? 'Pagado'
        : 'No pagado',
  },
  {
    label: 'policyStatus',
    displayValue: 'Estado',
    value: policyInfo.status === 'ACTIVE' ? 'Activa' : 'Inactiva',
  },
];

const renderIconsWhenNecessary = (e: FieldType): React.ReactElement => {
  if (e.label !== 'paymentStatus' && e.label !== 'policyStatus') return null;
  if (e.value === 'Activa' || e.value === 'Pagado')
    return <BadgeCheckIcon className="text-teal-500 h-5 ml-1" />;
  return <ExclamationIcon className="text-red-500 h-5 ml-1" />;
};

export function TableRow({ mw, i }: { mw: MechanicalWarranty; i: number }) {
  const [isOpen, setIsOpen] = useState(false);
  const [mechanicalWarranty, setMechanicalWarranty] =
    useState<MechanicalWarrantyInfoType>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const swapDetailPosition = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isOpen) {
      const fetchMechanicalWarrranty = async () => {
        try {
          setIsLoading(true);
          const token = await getAccessTokenSilently();
          const response = await getMechanicalWarranty(token, mw.carId);
          setMechanicalWarranty(response);
        } finally {
          setIsLoading(false);
        }
      };
      fetchMechanicalWarrranty();
    }
  }, [isOpen]);

  return (
    <>
      <tr key={mw.id} className={i % 2 === 0 ? undefined : 'bg-gray-50'}>
        <td className="whitespace-nowrap px-3 py-4 sm:pl-6 text-sm font-medium text-gray-900">
          {mw.numPolicy}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {mw.plate}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {mw.annualPremium} €
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {parseDayMonthYear(mw.issueDate)}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-md text-gray-500">
          <div className="h-9 w-9 cursor-pointer hover:bg-gray-100 hover:rounded-full flex items-center justify-center">
            {isOpen ? (
              <ChevronUpIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                onClick={swapDetailPosition}
              />
            ) : (
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400 cursor-pointer"
                onClick={swapDetailPosition}
              />
            )}
          </div>
        </td>
      </tr>
      {isOpen && mechanicalWarranty && (
        <td colSpan={6}>
          <div
            className={classNames(
              i % 2 === 0 ? undefined : 'bg-gray-50',
              'overflow-hidden h-full w-full flex flex-col gap-5',
            )}
          >
            <div className="px-4 py-5 sm:p-6">
              <div className="mb-3">
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    {infoFields(mechanicalWarranty)?.map((e: FieldType) => (
                      <React.Fragment key={e.label}>
                        <div className="sm:col-span-1" key={e.label}>
                          <dt className="text-sm font-medium text-gray-500">
                            {e.displayValue}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 flex">
                            {e.value || '---'} {renderIconsWhenNecessary(e)}
                          </dd>
                        </div>
                      </React.Fragment>
                    ))}
                  </dl>
                </div>
              </div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span
                    className={classNames(
                      i % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                      'px-2 text-sm text-gray-500',
                    )}
                  >
                    Beneficiario
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    {beneficiaryFields(mechanicalWarranty)?.map(
                      (e: FieldType) => (
                        <React.Fragment key={e.label}>
                          <div className="sm:col-span-1" key={e.label}>
                            <dt className="text-sm font-medium text-gray-500">
                              {e.displayValue}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 flex">
                              {e.value || '---'}
                            </dd>
                          </div>
                        </React.Fragment>
                      ),
                    )}
                  </dl>
                </div>
              </div>
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span
                    className={classNames(
                      i % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                      'px-2 text-sm text-gray-500',
                    )}
                  >
                    Vehículo
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <div className="px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    {vehicleFields(mechanicalWarranty)?.map((e: FieldType) => (
                      <React.Fragment key={e.label}>
                        <div className="sm:col-span-1" key={e.label}>
                          <dt className="text-sm font-medium text-gray-500">
                            {e.displayValue}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 flex">
                            {e.value || '---'}
                          </dd>
                        </div>
                      </React.Fragment>
                    ))}
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </td>
      )}
      {isLoading && !mechanicalWarranty && (
        <td colSpan={6}>
          <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5 justify-center items-center w-full flex flex-col gap-5 h-[200px]">
            <Spinner size={10} color="text-blue-500" marginTop={0} />
          </div>
        </td>
      )}
    </>
  );
}
