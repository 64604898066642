import React, { useContext, useState } from 'react';
import { DocumentTextIcon, PaperClipIcon } from '@heroicons/react/outline';

import GenerateDocumentationModal from './GenerateDocumentationModal/GenerateDocumentationModal';
import EditorProvisionalModal from '../../../../../components/EditorProvisionalModal';
import FileLink from '../../../../../components/FileLink';
import Spinner from '../../../../../components/Spinner';
import NotifierModal from '../../../../../components/NotifierModal';
import { transferDetailsContext } from '../../../../../context/transfer-details.context';
import useExtraFiles from '../../../../../hooks/useExtraFiles';
import { ENTITY_TYPES } from '../../../../../helpers/enums';
import ExtraFilesDragAndDrop from '../../../../../components/ExtraFilesDragAndDrop';
import TrafficReportSection from './TrafficReportSection';
import DeleteExtraFileModal from '../../../../../components/DeleteExtraFilesModal';

export default function DocumentsSection({ setModalType }) {
  const [isProvisionalOpen, setIsProvisionalOpen] = useState(false);
  const [documentationModalOpen, setDocumentationModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const { transfer, trafficReport, forceUpdate } = useContext(
    transferDetailsContext,
  );
  const { transaction } = transfer;

  const {
    extraFilesLoading,
    extraFiles,
    handleViewExtraFile,
    handleDeleteExtraFile,
    extraFilesWithLongName,
    handleUploadExtraFiles,
    modalError,
    resetModalError,
  } = useExtraFiles({
    tramitType: ENTITY_TYPES.TRANSACTION,
    entity: transaction,
  });

  const handleSetFileToDelete = (file) => {
    setFileToDelete(file);
  };
  const clearFileToDelete = () => {
    setFileToDelete(null);
  };

  return (
    <>
      <EditorProvisionalModal
        isOpen={isProvisionalOpen}
        setIsOpen={setIsProvisionalOpen}
      />
      <GenerateDocumentationModal
        isOpen={documentationModalOpen}
        setIsOpen={setDocumentationModalOpen}
      />
      <DeleteExtraFileModal
        isOpen={Boolean(fileToDelete)}
        close={clearFileToDelete}
        file={fileToDelete}
        handleDeleteExtraFile={handleDeleteExtraFile}
      />
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Documentos</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Contrato de compraventa (físico)
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.saleContract ? (
                  <>
                    <FileLink source={transaction?.saleContract} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setModalType('sellBuyContract')}
                      className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setModalType('sellBuyContract')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            {transaction.trafficReport && (
              <TrafficReportSection
                uri={trafficReport}
                forceUpdate={forceUpdate}
                transactionCode={transaction.transactionCode}
              />
            )}
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Permiso de circulación provisional
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.temporaryCirculationDoc ? (
                  <>
                    <FileLink source={transaction.temporaryCirculationDoc} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        setIsProvisionalOpen(true);
                      }}
                      className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setIsProvisionalOpen(true);
                    }}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">Factura Holded</span>
              </div>
              <div className="ml-4 shrink-0">
                {transaction?.holdedInvoiceId ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://app.holded.com/sales/revenue#open:invoice-${transaction.holdedInvoiceId}`}
                    className="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Ver
                  </a>
                ) : (
                  <span className="font-medium text-gray-500 cursor-not-allowed">
                    No Disponible
                  </span>
                )}
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Factura profesional
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.proInvoice ? (
                  <>
                    <FileLink source={transaction.proInvoice} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setModalType('proInvoice')}
                      className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setModalType('proInvoice')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
              <div className="w-0 flex-1 flex items-center">
                <PaperClipIcon
                  className="shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2 flex-1 w-0 truncate">
                  Justificante de presentación (profesional)
                </span>
              </div>
              <div className="ml-4 shrink-0 flex space-x-4">
                {transaction.presentationProof ? (
                  <>
                    <FileLink source={transaction.presentationProof} />
                    <span className="text-gray-300" aria-hidden="true">
                      |
                    </span>
                    <button
                      type="button"
                      onClick={() => setModalType('presentationProof')}
                      className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                    >
                      Reemplazar
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={() => setModalType('presentationProof')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-indigo-500"
                  >
                    Añadir
                  </button>
                )}
              </div>
            </li>
            <li className="group pl-3 pr-4 py-3 flex flex-col items-center justify-between text-sm">
              <div className="flex items-center justify-between w-full">
                <div className="w-0 flex-1 flex items-center">
                  <PaperClipIcon
                    className="shrink-0 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-2 flex-1 w-0 truncate">
                    Archivos extra
                  </span>
                  {extraFilesLoading && (
                    <Spinner color="text-blue-700" size="4" />
                  )}
                </div>
              </div>
              {extraFiles?.length > 0 && (
                <div className="flex items-center justify-between w-full">
                  <ul className="w-full pl-5 mt-3">
                    {extraFiles.map((file) => (
                      <li
                        key={file}
                        className="flex items-center justify-between w-full p-2 "
                      >
                        <div className="w-0 flex-1 flex items-center">
                          <span className="ml-2 flex-1 truncate">
                            {file?.split('-hash-')[0]}
                          </span>
                        </div>
                        <div className="ml-4 shrink-0">
                          <button
                            type="button"
                            onClick={() => handleViewExtraFile(file)}
                            className="cursor-pointer font-medium text-blue-600 hover:text-blue-500"
                          >
                            Ver
                          </button>
                          <button
                            type="button"
                            onClick={() => handleSetFileToDelete(file)}
                            className="ml-3 cursor-pointer font-medium text-red-600 hover:text-red-500"
                          >
                            Eliminar
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {modalError && (
                <NotifierModal
                  text={extraFilesWithLongName.map((extraFile) => (
                    <p className="my-4">{`El nombre del archivo ${extraFile.name.slice(
                      0,
                      30,
                    )}... es demasiado largo`}</p>
                  ))}
                  buttonText="Ok"
                  onClick={resetModalError}
                />
              )}
              <ExtraFilesDragAndDrop
                handleUploadExtraFiles={handleUploadExtraFiles}
              />
            </li>
          </ul>
          <button
            type="button"
            className="text-white bg-indigo-500 hover:bg-indigo-700 rounded-md px-4 py-1 mt-2 text-lg flex items-center gap-2"
            onClick={() => setDocumentationModalOpen(true)}
          >
            Documentación para gestoría <DocumentTextIcon className="h-6" />
          </button>
        </dd>
      </div>
    </>
  );
}
