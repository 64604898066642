import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment } from 'react';
import Spinner from '../../../../components/Spinner';
import { classNames } from '../../../../helpers';

export default function TextFilterSearch({
  data,
  loadData,
  title,
  filterName,
  stateName,
  searchValue,
  handleChange,
  searchParams,
  handleFilter,
  callback,
  isLoading,
}) {
  return (
    <Menu as="span" className="-ml-px relative block">
      <Menu.Button
        type="button"
        className="flex items-center justify-center mr-4 pr-3 text-gray-600 gap-x-2 border-r-[1px]"
      >
        <button
          type="button"
          className="flex gap-1 items-center"
          onKeyDown={callback}
          onClick={loadData}
        >
          {title}
        </button>
        <ChevronDownIcon
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
          onClick={loadData}
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-20 origin-top-right absolute right-0 mt-2 -mr-1 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <input
            type="text"
            value={searchValue}
            name={stateName}
            onChange={handleChange}
            className="w-full px-4 py-2 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            autoComplete="off"
          />
          <div className="h-fit max-h-48 overflow-y-scroll">
            {isLoading && (
              <div className="grid place-items-center">
                <Spinner
                  color="text-blue-700"
                  size={5}
                  marginTop={5}
                  marginBottom={5}
                />
              </div>
            )}
            {data.map(({ key, name }) => (
              <Menu.Item key={key}>
                {({ active }) => (
                  <button
                    type="button"
                    onClick={() => handleFilter(filterName, key)}
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'px-4 py-2 text-sm w-full text-left flex items-center',
                      searchParams.get(filterName) === key && 'bg-blue-100',
                    )}
                  >
                    <span
                      className="mr-2 rounded-full truncate"
                      aria-hidden="true"
                    />
                    {name}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
