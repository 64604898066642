import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Pagination from '../components/Pagination';
import Table from './components/Table';
import GenericTableSkeletonLoader from '../components/GenericTableSkeletonLoader';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';

const LIMIT = 10;

export type MechanicalWarranty = {
  id: string;
  numPolicy: number;
  effectiveDate: Date;
  issueDate: Date;
  annualPremium: number;
  commissionAmount: number;
  carId: string;
  accountId: string;
  plate: string;
  policyUUID: string;
  createdAt: Date;
  updatedAt: Date;
};

export default function MechanicalWarrantiesList() {
  const [isLoading, setIsLoading] = useState(true);
  const [mechanicalWarraties, setMechanicalWarraties] = useState<
    MechanicalWarranty[]
  >([]);
  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);
  const [page, setPage] = useState(
    getSingleParam('page') ? Number(getSingleParam('page')) : 0,
  );
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setSingleParam('page', page.toString());
  }, [page]);

  const { getAccessTokenSilently } = useAuth0();

  const userInfo = useContext(userInfoContext);

  const accountId = userInfo.accountInfo?.id;

  useEffect(() => {
    (async () => {
      if (!accountId) return;
      setIsLoading(true);
      const token = await getAccessTokenSilently();

      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/account/${accountId}/mechanical-warranties?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );

      setMechanicalWarraties(data.mechanicalWarranties);
      setTotalCount(data.total);
      setTotalPages(Math.ceil(data.total / LIMIT));
      setIsLoading(false);
    })();
  }, [params, accountId]);

  return (
    <>
      <div className="bg-gray-100 p-6">
        <div className="flex justify-between">
          <h1 className="text-3xl font-semibold">Garantías Mecánicas</h1>
        </div>
        {isLoading ? (
          <GenericTableSkeletonLoader />
        ) : (
          <Table data={mechanicalWarraties} />
        )}
      </div>
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </>
  );
}
