import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

import TransfersFilters from './components/TransfersFilters/TransfersFilters';
import Pagination from '../components/Pagination';
import TransfersTable from './components/TransfersTable/TransfersTable';
import TransferTableSkeletonLoader from './components/TransferTableSkeletonLoader/TransferTableSkeletonLoader';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import NewTransferModal from '../modals/NewTransferModal/NewTransferModal';

export interface TransferForTable {
  plate?: string;
  tramitCode: string;
  type:
    | 'seller'
    | 'buyer'
    | 'intermediary'
    | 'batecom_sale'
    | 'batecom_purchase';
  status: number;
  createdAt: string;
  hasIncident: boolean;
}

export interface TransfersTableProps {
  transfers: TransferForTable[];
}

type TransferFromQuery = {
  tramitCode: string;
  createdAt: string;
  plate: string;
  status: number;
  hasIncident: boolean;
  type: string;
  intermediaryId: string;
  sellerId: string;
  buyerId: string;
};

const LIMIT = 10;

export default function TransfersList() {
  const [loadingTransfers, setLoadingTransfers] = useState(true);
  const [transfers, setTransfers] = useState<TransferForTable[]>([]);
  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);
  const [page, setPage] = useState(
    getSingleParam('page') ? Number(getSingleParam('page')) : 0,
  );
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setSingleParam('page', page.toString());
  }, [page]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    (async () => {
      setLoadingTransfers(true);
      const token = await getAccessTokenSilently();

      const { data: fetchedTransfers } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/list-transactions-pro?${params}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setTransfers(
        fetchedTransfers.transfers.map((t: TransferFromQuery) => ({
          plate: t.plate,
          tramitCode: t.tramitCode,
          type: t.type,
          status: t.status,
          createdAt: t.createdAt,
          hasIncident: t.hasIncident,
        })),
      );
      setTotalCount(fetchedTransfers.count);
      setTotalPages(Math.ceil(fetchedTransfers.count / LIMIT));
      setLoadingTransfers(false);
    })();
  }, [params]);

  return (
    <>
      <div className="bg-gray-100 p-6 w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold">Compraventas</h1>
        </div>
        <div className="flex justify-between h-full py-4">
          <TransfersFilters />
          <NewTransferModal />
        </div>
        {loadingTransfers ? (
          <TransferTableSkeletonLoader />
        ) : (
          <TransfersTable transfers={transfers} />
        )}
      </div>
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </>
  );
}
