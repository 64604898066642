import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Pagination from '../components/Pagination';
import { REGISTRATION_STATUS } from '../shared/registration-status';
import GenericTable, { GenericTableRow } from '../components/GenericTable';
import GenericTableSkeletonLoader from '../components/GenericTableSkeletonLoader';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';
import NewRegistrationModal from '../modals/NewRegistrationModal/NewRegistrationModal';

const LIMIT = 10;

type FullRegistrationResponse = {
  registrations: RegistrationRowResponse[];
  count: number;
};

type RegistrationRowResponse = {
  registrationCode: string;
  status: REGISTRATION_STATUS;
  createdAt: string;
  hasIncident: boolean;
};

export default function RegistrationsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [tramits, setTramits] = useState<GenericTableRow[]>([]);
  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);
  const [page, setPage] = useState(
    getSingleParam('page') ? Number(getSingleParam('page')) : 0,
  );
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setSingleParam('page', page.toString());
  }, [page]);

  const { getAccessTokenSilently } = useAuth0();

  const userInfo = useContext(userInfoContext);

  const accountId = userInfo.accountInfo?.id;

  useEffect(() => {
    (async () => {
      if (!accountId) return;
      setIsLoading(true);
      const token = await getAccessTokenSilently();

      const { data }: { data: FullRegistrationResponse } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/v1/registrations/${accountId}?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );

      setTramits(
        data.registrations.map((r: RegistrationRowResponse) => ({
          tramitCode: r.registrationCode,
          tramitType: 'registration',
          status: r.status,
          createdAt: r.createdAt,
          hasIncident: r.hasIncident,
        })),
      );
      setTotalCount(data.count);
      setTotalPages(Math.ceil(data.count / LIMIT));
      setIsLoading(false);
    })();
  }, [params, accountId]);

  return (
    <>
      <div className="bg-gray-100 p-6">
        <div className="flex justify-between">
          <h1 className="text-3xl font-semibold">Matriculaciones</h1>
          <NewRegistrationModal />
        </div>
        {isLoading ? (
          <GenericTableSkeletonLoader />
        ) : (
          <GenericTable tramits={tramits} />
        )}
      </div>
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </>
  );
}
