import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Popover, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import './index.css';
import 'react-tooltip/dist/react-tooltip.css';
import Main from './routes/main';
import Spinner from './components/Spinner';

function Home() {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white  translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <Popover>
            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav
                className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                aria-label="Global"
              >
                <div className="flex items-center flex-grow shrink-0 lg:grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <a
                      href="https://swipoo.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="sr-only">Workflow</span>
                      <img
                        alt="logo"
                        className="h-8 w-auto sm:h-10"
                        src="https://elreferente.es/wp-content/uploads/2022/01/Logo_swipoo.png"
                      />
                    </a>
                    <div className="-mr-2 flex items-center md:hidden" />
                  </div>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-10 top-0 inset-x-0 p-2 transition  origin-top-right md:hidden"
              >
                <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="px-5 pt-4 flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://elreferente.es/wp-content/uploads/2022/01/Logo_swipoo.png"
                        alt="logo"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close main menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Gestión online</span>
                <br />
                <span className="block text-primaryBlue xl:inline">
                  de transferencias
                </span>
              </h1>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md">
                  <button
                    onClick={loginWithRedirect}
                    type="button"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 mt-20"
                  >
                    Acceder
                  </button>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://images.unsplash.com/photo-1585390062628-be8608aa7d83?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80"
          alt="gestion online"
        />
      </div>
    </div>
  );
}

function LoginPresentation() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading)
    return (
      <div className="w-screen h-screen flex flex-col justify-center items-center gap-y-4">
        <img
          src="/swipoo-logo.svg"
          alt="swipoo logo"
          width="180"
          height="180"
        />
        <Spinner color="text-blue-700" size={10} marginTop={28} />
      </div>
    );
  if (isAuthenticated) return <Main />;
  return <Home />;
}

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="swipoo.eu.auth0.com"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={
        window.location.hostname === 'localhost'
          ? 'https://api-sandbox.swipoo.com'
          : process.env.REACT_APP_BASE_API_URL
      }
    >
      <LoginPresentation />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
