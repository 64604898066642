import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';

import parseDate from '../helpers/parseDate';
import {
  REGISTRATION_STATUS_PRO,
  REGISTRATION_STATUS,
  SHIPMENT_STATUS,
} from '../shared/registration-status';
import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';

export interface GenericTableRow {
  tramitCode: string;
  status: REGISTRATION_STATUS;
  createdAt: string;
  hasIncident: boolean;
  tramitType: string;
}

export interface GenericTableProps {
  tramits: GenericTableRow[];
}

export default function GenericTable({ tramits }: GenericTableProps) {
  const [dateSort, setDateSort] = useState('DESC');

  const emptyLabel = { label: '', colors: '' };

  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);

  useEffect(() => {
    const dateSortParam = getSingleParam('dateSort') || 'DESC';
    setDateSort(dateSortParam);
  }, [params]);

  const notSubmittedLabel = {
    label: 'No presentado',
    colors: 'bg-gray-100 text-gray-800',
  };
  const submittedToAgencyLabel = {
    label: 'Presentado a gestoría',
    colors: 'bg-yellow-100 text-yellow-800',
  };
  const submittedToDGTLabel = {
    label: 'Presentado a DGT',
    colors: 'bg-blue-100 text-blue-800',
  };
  const finishedByDGTLabel = {
    label: 'Finalizado por DGT',
    colors: 'bg-green-100 text-green-800',
  };

  const getTransferStatusLabel = (status: REGISTRATION_STATUS) => {
    if (REGISTRATION_STATUS_PRO.FINISHED_BY_DGT.includes(status)) {
      return finishedByDGTLabel;
    }
    if (REGISTRATION_STATUS_PRO.SUBMITTED_TO_DGT.includes(status)) {
      return submittedToDGTLabel;
    }
    if (REGISTRATION_STATUS_PRO.SUBMITTED_TO_AGENCY.includes(status)) {
      return submittedToAgencyLabel;
    }
    if (REGISTRATION_STATUS_PRO.NOT_SUBMITTED.includes(status)) {
      return notSubmittedLabel;
    }

    return emptyLabel;
  };

  const shippedLabel = {
    label: 'Enviado',
    colors: 'bg-yellow-100 text-yellow-800',
  };

  const deliveredLabel = {
    label: 'Entregado',
    colors: 'bg-green-100 text-green-800',
  };

  const getShipmentStatusLabel = (status: REGISTRATION_STATUS) => {
    if (SHIPMENT_STATUS.DELIVERED.includes(status)) {
      return deliveredLabel;
    }

    if (SHIPMENT_STATUS.SENT.includes(status)) {
      return shippedLabel;
    }

    return emptyLabel;
  };

  const getRouteByTramit = (tramit: GenericTableRow) => {
    if (tramit.tramitType === 'registration') {
      return `/registration/${tramit.tramitCode}`;
    }
    return '';
  };

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3.5 pr-3 sm:pl-6 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Estado del trámite
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Estado del envío
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center"
                    onClick={
                      dateSort === 'DESC'
                        ? () => setSingleParam('dateSort', 'ASC')
                        : () => setSingleParam('dateSort', 'DESC')
                    }
                  >
                    Fecha de creación{' '}
                    {dateSort === 'ASC' ? (
                      <ChevronUpIcon className="h-3" />
                    ) : (
                      <ChevronDownIcon className="h-3" />
                    )}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Ver</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {tramits.length ? (
                  tramits.map((t, personIdx) => {
                    const route = getRouteByTramit(t);
                    return (
                      <tr
                        key={t.tramitCode}
                        className={`${
                          personIdx % 2 === 0 ? undefined : 'bg-gray-50'
                        } hover:bg-gray-100`}
                      >
                        <td className="whitespace-nowrap px-3 py-4 pr-3 sm:pl-6 text-sm text-gray-500">
                          {t.tramitCode}{' '}
                          {t.hasIncident && (
                            <ExclamationCircleIcon className="h-5 text-red-500" />
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span
                            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                              getTransferStatusLabel(t.status).colors
                            }`}
                          >
                            {getTransferStatusLabel(t.status).label}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span
                            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                              getShipmentStatusLabel(t.status).colors
                            }`}
                          >
                            {getShipmentStatusLabel(t.status).label}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {parseDate(t.createdAt)}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <Link
                            to={route}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Ver
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="h-[530px]" colSpan={6}>
                      <div className="h-full w-full flex items-center justify-center flex-col gap-5">
                        <p className="text-8xl rotate-90">: (</p>
                        <p className="w-2/3 text-center text-md">
                          Vaya, no hemos encontrado ningun trámite con estos
                          filtros, prueba a cambiarlos o, si crees que se trata
                          de un error, contacta con nosotros.
                        </p>{' '}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
