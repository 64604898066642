import React from 'react';
import { parseDayMonthYearWithSlash } from '../../../../helpers';
import { statusEnums } from '../../../../helpers/enums';

export default function ActiveFilters({
  statusItems,
  incidentTypeItems,
  ccaaItems,
  specialFilters,
  searchParams,
  handleFilter,
  companyMembers,
  companies,
  accounts,
  labelOptions,
  handleLabelsFilter,
  agents,
  minDateStatusChange,
  setMinDateStatusChange,
  maxDateStatusChange,
  setMaxDateStatusChange,
  initialState,
  setInitialState,
  finalState,
  setFinalState,
  hosts,
  batecomFilterItems,
}) {
  return (
    <span className="flex justify-start items-center gap-x-3 z-0 mb-4 rounded-md bg-gray-50">
      <span className="uppercase text-gray-500 px-6 py-4 font-semibold text-xs">
        Filtros
      </span>
      {searchParams.get('minDateStatusChange') &&
        searchParams.get('maxDateStatusChange') &&
        searchParams.get('finalState') && (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => {
              setMinDateStatusChange('');
              setMaxDateStatusChange('');
              setInitialState('');
              setFinalState('');
            }}
          >
            <span
              className="bg-blue-500 mr-2 shrink-0 inline-block h-2 w-2 rounded-full"
              aria-hidden="true"
            />
            Cambio de estado entre{' '}
            {parseDayMonthYearWithSlash(new Date(minDateStatusChange))} -{' '}
            {parseDayMonthYearWithSlash(new Date(maxDateStatusChange))}{' '}
            {initialState &&
              `de ${
                statusEnums.find((status) => status.id === Number(initialState))
                  .label
              } `}
            a{' '}
            {
              statusEnums.find((status) => status.id === Number(finalState))
                .label
            }
            <span className="text-gray-400">x</span>
          </button>
        )}
      {statusItems
        .filter(
          (i) =>
            searchParams.get('status') &&
            Number(searchParams.get(i.key)) === i.value,
        )
        .map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter(i.key, i.value)}
          >
            <span
              className={`${i.color} mr-2 shrink-0 inline-block h-2 w-2 rounded-full`}
              aria-hidden="true"
            />
            {i.name} <span className="text-gray-400">x</span>
          </button>
        ))}
      {incidentTypeItems
        .filter((incident, index) => {
          if (index === 0) {
            return searchParams.get('hasIncident');
          }
          return (
            searchParams.get('incidentType') &&
            searchParams.get(incident.key) === incident.value
          );
        })
        .map((incident) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter(incident.key, incident.value)}
          >
            {incident?.color && (
              <span
                className={`${incident.color} mr-2 shrink-0 inline-block h-2 w-2 rounded-full`}
                aria-hidden="true"
              />
            )}
            {incident.name === 'Todas'
              ? 'Todas las incidencias'
              : incident.name}{' '}
            <span className="text-gray-400">x</span>
          </button>
        ))}
      {ccaaItems
        ?.filter(
          (i) =>
            searchParams.get('ccaa') && searchParams.get(i.key) === i.value,
        )
        ?.map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter(i.key, i.value)}
          >
            {i?.color && (
              <span
                className={`${i.color} mr-2 shrink-0 inline-block h-2 w-2 rounded-full`}
                aria-hidden="true"
              />
            )}
            {i.name} <span className="text-gray-400">x</span>
          </button>
        ))}
      {specialFilters
        ?.filter((i) => searchParams.get(i.key))
        ?.map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter(i.key, i.value)}
          >
            {i.name} <span className="text-gray-400">x</span>
          </button>
        ))}
      {companyMembers
        ?.filter((i) => searchParams.get('assignedTo') === i.email)
        ?.map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter('assignedTo', i.email)}
          >
            {i.name} <span className="text-gray-400">x</span>
          </button>
        ))}
      {companies
        .filter((i) => searchParams.get('companyId') === i.id)
        .map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter('companyId', i.id)}
          >
            {i.name} <span className="text-gray-400">x</span>
          </button>
        ))}
      {accounts
        ?.filter((i) => searchParams.get('accountId') === i.id)
        ?.map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter('accountId', i.id)}
          >
            {i.name} <span className="text-gray-400">x</span>
          </button>
        ))}
      {labelOptions
        .filter((i) => searchParams.getAll('labels').includes(i.id))
        .map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleLabelsFilter(i.id)}
          >
            {i.name} <span className="text-gray-400">x</span>
          </button>
        ))}
      {agents
        ?.filter((i) => searchParams.get('agentId') === i.id)
        ?.map((i) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter('agentId', i.id)}
          >
            {i.name} {i.surname} <span className="text-gray-400">x</span>
          </button>
        ))}
      {hosts
        ?.filter((host) => searchParams.get('host') === host)
        ?.map((host) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter('host', host)}
          >
            {host} <span className="text-gray-400">x</span>
          </button>
        ))}
      {batecomFilterItems
        ?.filter(
          (tramitType) => searchParams.get('tramitType') === tramitType.value,
        )
        ?.map((tramitType) => (
          <button
            type="button"
            className="border rounded-2xl py-0 px-3 h-8 bg-white text-sm"
            onClick={() => handleFilter('tramitType', tramitType.value)}
          >
            {tramitType.name} <span className="text-gray-400">x</span>
          </button>
        ))}
    </span>
  );
}
