import React, { DispatchWithoutAction, useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Dialog } from '@headlessui/react';

import Button from '../../components/Button';
import BaseModal from '../../components/BaseModal';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import Spinner from '../../components/Spinner';
import { validateFrameNumber } from '../../helpers/validateFrameNumber';
import { validatePlate } from '../../helpers/validatePlate';

class InvalidPlateOrFrameNumberError extends Error {
  constructor() {
    super('Formato de matrícula o bastidor incorrecto');
  }
}

export default function NewTrafficReportModal({
  reloadTrafficReports,
}: {
  reloadTrafficReports: DispatchWithoutAction;
}): JSX.Element {
  const [text, setText] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alreadyRequested, setAlreadyRequested] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { setToasterData } = useContext(toasterContext);

  const { getAccessTokenSilently } = useAuth0();

  const toggleModalOpen = () => setIsModalOpen((prev) => !prev);
  const closeModal = () => {
    setIsModalOpen(false);
    setText('');
    setIsLoading(false);
    setAlreadyRequested(false);
  };

  const isPlate = validatePlate(text);
  const isFrameNumber = validateFrameNumber(text);
  const isValidInput = isPlate || isFrameNumber;

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      setAlreadyRequested(false);

      if (!isPlate && !isFrameNumber) {
        throw new InvalidPlateOrFrameNumberError();
      }

      const token = await getAccessTokenSilently();

      const { data } = await axios.post(
        `${process.env.REACT_APP_SAAB_URL}/traffic-report`,
        {
          ...(isPlate && { plate: text }),
          ...(isFrameNumber && { frameNumber: text }),
        },
        { headers: { Authorization: `Bearer ${token}` } },
      );
      reloadTrafficReports();
      setToasterData({
        title: 'Informe de Tráfico obtenido',
        message: 'Podrás revisarlo desde el listado',
        type: 'SUCCESS',
      });
      closeModal();

      const anchor = document.createElement('a');
      anchor.href = data.uri;
      anchor.target = '_blank';
      anchor.click();
    } catch (e) {
      let errorMessage;

      if (e instanceof InvalidPlateOrFrameNumberError) {
        errorMessage = e.message;
      } else if ((e as AxiosError)?.response?.status === 429) {
        errorMessage =
          'El informe de este vehículo ya ha sido solicitado en las últimas 24 horas';
        setAlreadyRequested(true);
      } else {
        errorMessage =
          'Ha ocurrido un error al solicitar el informe de tráfico';
      }

      setToasterData({
        title: 'Error al solicitar informe',
        message: errorMessage,
        type: 'ERROR',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value.toUpperCase());
  };

  return (
    <>
      <BaseModal
        isOpen={isModalOpen}
        onClose={closeModal}
        enableCloseButton={!isLoading}
        width="600"
      >
        <div className="h-fit bg-white rounded-md p-8 flex flex-col items-center justify-center">
          {isLoading ? (
            <div className="flex flex-col items-center justify-center gap-y-2">
              <span>Solicitando informe, puede tardar hasta 30 segundos</span>
              <Spinner color="text-blue-700" size={10} marginTop={0} />
            </div>
          ) : (
            <>
              <div className="sm:flex sm:items-start mb-4">
                <div className="mt-3 text-center grow sm:mt-0">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-medium leading-6 text-gray-900"
                  >
                    Solicitar Informe de Tráfico
                  </Dialog.Title>
                </div>
              </div>
              <div className="flex flex-col gap-y-4">
                <div className="flex flex-col items-center gap-y-2">
                  <label htmlFor="identifier">
                    Introduce la matrícula o bastidor
                  </label>
                  <input
                    type="text"
                    id="identifier"
                    data-testid="identifier"
                    className="rounded-md border border-gray-300 p-2 w-full text-center"
                    onChange={handleChange}
                    value={text}
                  />
                </div>
                <Button
                  bgColor={isValidInput ? 'bg-blue-600' : 'bg-gray-600'}
                  hoverBgColor={isValidInput ? 'bg-blue-700' : 'bg-gray-700'}
                  textColor="white"
                  text="Solicitar"
                  callback={handleSubmit}
                  disabled={!isValidInput}
                />
                {alreadyRequested && (
                  <span className="text-red-500 text-sm max-w-[250px]">
                    Ya has solicitado un informe de tráfico para este vehículo
                    en las últimas 24 horas
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </BaseModal>

      <div className="self-center">
        <Button
          text="Nuevo informe"
          bgColor="bg-blue-600"
          hoverBgColor="bg-blue-700"
          textColor="white"
          callback={toggleModalOpen}
          disabled={isModalOpen}
        />
      </div>
    </>
  );
}
