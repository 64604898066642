import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

import { FILE_TYPE } from '../types/types';

export type FileOCRResponse = {
  data: {
    [key: string]: string;
  };
  signedUrl: string;
};

export default function usePerformOCR(): {
  performOCR: (s3Key: string, fileType: FILE_TYPE) => Promise<FileOCRResponse>;
} {
  const BASE_URL = process.env.REACT_APP_BASE_API_URL;
  const { getAccessTokenSilently } = useAuth0();

  const performOCR = async (
    s3Key: string,
    fileType: FILE_TYPE,
  ): Promise<FileOCRResponse> => {
    const token = await getAccessTokenSilently();
    return axios
      .get(`${BASE_URL}/perform-ocr/${fileType}/${s3Key}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data);
  };

  return {
    performOCR,
  };
}
