import React, { Fragment, useEffect, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

export default function FuzzySearch({
  values,
  label,
  prefilledValue,
  onSelectedChange,
}) {
  const [selected, setSelected] = useState(
    values.find((e) => e?.[label] === prefilledValue) || {
      [label]: prefilledValue,
    },
  );
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!selected && query === '') return;
    onSelectedChange(selected[label]);
  }, [selected]);

  const filteredValues =
    query === ''
      ? values
      : values?.filter((value) =>
          value[label]
            ?.toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')),
        );

  return (
    <Combobox value={selected} onChange={setSelected} nullable>
      <div className="relative mt-1">
        <div className="relative w-full cursor-default bg-white text-left rounded-md border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            displayValue={(person) => person?.[label]}
            onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 h-44 focus:outline-none sm:text-sm">
            {query?.length > 0 && (
              <Combobox.Option
                value={{ [label]: query }}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-blue-600 text-white' : 'text-gray-900'
                  }`
                }
              >
                {query}
              </Combobox.Option>
            )}
            {filteredValues.slice(0, 100).map((person) => (
              <Combobox.Option
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-blue-600 text-white' : 'text-gray-900'
                  }`
                }
                value={person}
                key={JSON.stringify(person)}
              >
                {({ selected: selectedOption }) => (
                  <span
                    className={`block truncate ${
                      selectedOption ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    {person[label]}
                  </span>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
