/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { PaperClipIcon, ExclamationIcon } from '@heroicons/react/solid';

import axios from 'axios';
import { useServices } from '../../../services';
import { fuelDictionary, serviceTypeDict } from '../../../helpers/enums';
import EditorModal from '../../../components/EditorModal';
import FileLink from '../../../components/FileLink';
import { parseDayMonthYearWithSlash } from '../../../helpers';
import EditPlateModal from './components/EditPlateModal';
import BrandModelEditModal from './components/BrandModelEditModal/BrandModelEditModal';
import AssociatedTramits from '../../../components/AssociatedTramits';

export default function CarDetail() {
  const [car, setCar] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [isPlateModalOpen, setIsPlateModalOpen] = useState(false);
  const [isBrandModelModalOpen, setIsBrandModelModalOpen] = useState(false);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const { carId } = useParams();
  const { getCarById, getS3SignedUrl, getCarRelatedTramits } = useServices();

  useEffect(() => {
    const fetchCar = async () => {
      const fetchedCar = await getCarById(carId);
      setCar(fetchedCar);
    };
    fetchCar();
  }, [carId, updater]);

  const getFileInPdfAndDownload = async (url, plate) => {
    let parsedSource = url;
    if (!/https?:\/\//.test(url)) {
      const { signedUrl } = await getS3SignedUrl(
        'swipoo-transactions-files',
        url,
      );
      parsedSource = signedUrl;
    }
    const response = await axios.post(
      `${process.env.REACT_APP_PIAGGIO_BASE_URL}/image-to-pdf`,
      { url: parsedSource },
      { responseType: 'arraybuffer' },
    );
    const file = new Blob([response.data], { type: 'application/pdf' });
    const a = document.createElement('a');
    const fileURL = URL.createObjectURL(file);
    a.href = fileURL;
    a.download = `Permiso${plate}.pdf`;
    a.click();
  };

  return (
    car && (
      <>
        <EditorModal
          type={modalType}
          setType={setModalType}
          id={carId}
          save={forceUpdate}
          plate={car.plate}
          preloadedState={car}
        />
        <BrandModelEditModal
          car={car}
          isModalOpen={isBrandModelModalOpen}
          setIsModalOpen={setIsBrandModelModalOpen}
          save={forceUpdate}
        />
        <EditPlateModal
          car={car}
          isModalOpen={isPlateModalOpen}
          setIsModalOpen={setIsPlateModalOpen}
          save={forceUpdate}
        />
        <div>
          <div className="flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {car.brand} {car.model}
            </h3>
            <span className="ml-4 shrink-0">
              <button
                type="button"
                onClick={() => setIsBrandModelModalOpen(true)}
                className="rounded-md text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Editar
              </button>
            </span>
          </div>
          <div className="flex">
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {car.isMoto ? 'Moto' : 'Coche'}
            </p>
            <span className="ml-4 shrink-0 flex self-end">
              <button
                type="button"
                onClick={() => setModalType('isMoto')}
                className="rounded-md text-sm text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Editar
              </button>
            </span>
          </div>
        </div>
        <div className="mt-5 border-t border-gray-200">
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Matrícula</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{car.plate}</span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setIsPlateModalOpen(true)}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Kilometraje (km)
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {car.kms?.toLocaleString('es-ES')}
                </span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('kms')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Editar
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Nº de bastidor
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{car.frameNumber}</span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('frameNumber')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {car.frameNumber ? 'Editar' : 'Añadir'}
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Tipo de servicio
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {car.serviceType &&
                    `${car.serviceType} - ${serviceTypeDict[car.serviceType]}`}
                </span>
                {car.serviceType && car.serviceType !== 'B00' && (
                  <span className="flex-grow">
                    <ExclamationIcon className="text-yellow-500 h-6" />
                  </span>
                )}
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('serviceType')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {car.serviceType ? 'Editar' : 'Añadir'}
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Combustible</dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">{fuelDictionary[car.fuel]}</span>
                <span className="ml-4 shrink-0">
                  <button
                    type="button"
                    onClick={() => setModalType('fuel')}
                    className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {car.fuel ? 'Editar' : 'Añadir'}
                  </button>
                </span>
              </dd>
            </div>
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">
                Fecha de matriculación
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="flex-grow">
                  {parseDayMonthYearWithSlash(car.enrollmentDate)}
                </span>
                <button
                  type="button"
                  onClick={() => setModalType('enrollmentDate')}
                  className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Editar
                </button>
              </dd>
            </div>
            {car.cc && (
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">CC</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{car.cc} cc</span>
                </dd>
              </div>
            )}
            {car.cylinders && (
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Cilindros</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{car.cylinders}</span>
                </dd>
              </div>
            )}
            {car.powerKw && (
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">Kw</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{car.powerKw} kw</span>
                </dd>
              </div>
            )}
            {car.powerKw && (
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">CV</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">
                    {(Number(car.powerKw) * 1.3596).toFixed(2)} CV
                  </span>
                </dd>
              </div>
            )}
            {car.fiscalCV && (
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">CVF</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{car.fiscalCV}</span>
                </dd>
              </div>
            )}
            {car.value && (
              <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                  Valoración de hacienda
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{car.value} €</span>
                </dd>
              </div>
            )}
            <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
              <dt className="text-sm font-medium text-gray-500">Documentos</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        Ficha técnica
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {car.technicalSheet ? (
                        <>
                          <FileLink source={car.technicalSheet} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setModalType('technicalSheet')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setModalType('technicalSheet')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        Permiso de circulación
                      </span>
                    </div>
                    <div className="ml-4 shrink-0 flex space-x-4">
                      {car.circulationPermit ? (
                        <>
                          <FileLink source={car.circulationPermit} />
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() => setModalType('circulationPermit')}
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Reemplazar
                          </button>
                          <span className="text-gray-300" aria-hidden="true">
                            |
                          </span>
                          <button
                            type="button"
                            onClick={() =>
                              getFileInPdfAndDownload(
                                car.circulationPermit,
                                car.plate,
                              )
                            }
                            className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            PDF
                          </button>
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setModalType('circulationPermit')}
                          className="rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Añadir
                        </button>
                      )}
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </dl>
        </div>
        <AssociatedTramits
          entityId={car?.id}
          entityIdentifier={car?.plate}
          fetchFunction={getCarRelatedTramits}
        />
      </>
    )
  );
}
