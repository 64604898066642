import React from 'react';
import { CustomersTableProps } from '../..';

export default function CustomersTable({ customers }: CustomersTableProps) {
  return (
    <div className="mt-4 flex flex-col h-full" id="customers-table">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                  >
                    Nombre
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Dni
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Teléfono
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {customers?.length ? (
                  customers?.map((customer, personIdx) => (
                    <tr
                      key={customer.id}
                      className={`${
                        personIdx % 2 === 0 ? undefined : 'bg-gray-50'
                      } hover:bg-gray-100 h-[52px]`}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex items-center gap-2">
                        {customer.name} {customer.surname}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {customer.dni}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {customer.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {customer.phone}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="h-[530px]" colSpan={6}>
                      <div className="h-full w-full flex items-center justify-center flex-col gap-5">
                        <p className="text-8xl rotate-90">: (</p>
                        <p className="w-2/3 text-center text-md">
                          Vaya, no hemos encontrado ningún cliente. Si crees que
                          se trata de un error, contacta con nosotros.
                        </p>{' '}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
