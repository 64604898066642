import axios from 'axios';
import { useState, useEffect } from 'react';

export default function Dashboard() {
  const [dailyQuote, setDailyQuote] = useState({
    quote: '',
    author: '',
  });

  useEffect(() => {
    const fetchQuote = async () => {
      const { data: response } = await axios.get(
        'https://api.quotable.io/random',
      );
      setDailyQuote({
        quote: response.content,
        author: response.author,
      });
    };
    fetchQuote();
  }, []);

  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-900">
          {dailyQuote.quote}
        </h1>
        <p className="mt-4 text-base leading-7 text-gray-600">
          {dailyQuote.author}
        </p>
      </div>
    </main>
  );
}
