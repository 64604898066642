import React, { useContext, useEffect, useState } from 'react';

import SpecialFilter from '../SpecialFilter/SpecialFilter';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';

export interface FilterLabel {
  id: number;
  name: string;
  value: string;
}

export type SpecialFilterType =
  | 'all'
  | 'hasIncident'
  | 'ongoing'
  | 'completed'
  | 'canceled';

function TransfersFilters() {
  const { setSingleParam } = useContext(queryParamsContext);

  const [selectedSpecialFilter, setSelectedSpecialFilter] =
    useState<SpecialFilterType>('ongoing');

  useEffect(() => {
    if (selectedSpecialFilter === 'all') {
      setSingleParam('hasIncident', '');
      setSingleParam('ongoing', '');
      setSingleParam('completed', '');
      setSingleParam('canceled', '');
    }
    if (selectedSpecialFilter === 'hasIncident') {
      setSingleParam('hasIncident', 'true');
      setSingleParam('ongoing', '');
      setSingleParam('completed', '');
      setSingleParam('canceled', '');
    }
    if (selectedSpecialFilter === 'ongoing') {
      setSingleParam('ongoing', 'true');
      setSingleParam('hasIncident', '');
      setSingleParam('completed', '');
      setSingleParam('canceled', '');
    }
    if (selectedSpecialFilter === 'completed') {
      setSingleParam('completed', 'true');
      setSingleParam('ongoing', '');
      setSingleParam('hasIncident', '');
      setSingleParam('canceled', '');
    }
    if (selectedSpecialFilter === 'canceled') {
      setSingleParam('completed', '');
      setSingleParam('ongoing', '');
      setSingleParam('hasIncident', '');
      setSingleParam('canceled', 'true');
    }
  }, [selectedSpecialFilter]);

  return (
    <div className="flex flex-col">
      <div className="relative border-b border-gray-200 pb-5 sm:pb-0 z-9">
        <SpecialFilter
          selected={selectedSpecialFilter}
          setSelected={setSelectedSpecialFilter}
        />
      </div>
    </div>
  );
}

export default TransfersFilters;
