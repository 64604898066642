import React, { useCallback, useContext, useMemo, useState } from 'react';
import debounce from 'lodash.debounce';
import { IoIosSearch } from 'react-icons/io';
import { useAuth0 } from '@auth0/auth0-react';

import { search as searchService } from '../../../services/search';
import { toasterContext } from '../../../context/ToasterContext/ToasterContext';
import SearchResults from './SearchResults';
import Spinner from '../../../components/Spinner';

export type PrimitiveCompany = {
  id: string;
  legalName: string;
  nif: string;
};

export type PrimitiveUser = {
  id: string;
  name: string;
  lastName: string;
  dni: string;
  phone: string;
  email: string;
};

export type PrimitiveCar = {
  id: string;
  plate: string;
  frameNumber: string;
  brand: string;
  model: string;
};

export type PrimitiveTransaction = {
  id: string;
  transactionCode: string;
  status: number;
  buyer: PrimitiveUser | PrimitiveCompany;
  seller: PrimitiveUser | PrimitiveCompany;
  intermediary: PrimitiveUser | PrimitiveCompany;
  car: PrimitiveCar;
  accountId: string | null;
};

export type PrimitiveBate = {
  id: string;
  bateCode: string;
  status: string;
  buyer: PrimitiveCompany;
  seller: PrimitiveUser | PrimitiveCompany;
  car: PrimitiveCar;
  accountId: string | null;
};

export type PrimitiveRegistration = {
  id: string;
  registrationCode: string;
  status: string;
  buyer: PrimitiveUser | PrimitiveCompany;
  car: PrimitiveCar;
  accountId: string | null;
  createdAt?: Date;
};

export type PrimitiveTrafficReport = {
  id: string;
  plate: string | null;
  frameNumber: string | null;
  accountId: string | null;
  createdAt?: Date;
};

export type Hit = {
  entityId: string;
  entityType: 'transfer' | 'bate' | 'registration' | 'trafficReport';
  entityData:
    | PrimitiveTransaction
    | PrimitiveBate
    | PrimitiveRegistration
    | PrimitiveTrafficReport;
  createdAt: string;
  _highlightResult: unknown;
};

export type SearchResult = {
  hits: Hit[];
};

export default function Searchbox() {
  const [query, setQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchResult>(null);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { setToasterData } = useContext(toasterContext);

  const { getAccessTokenSilently } = useAuth0();

  const search = useCallback(async (value: string) => {
    try {
      setIsLoading(true);

      const token = await getAccessTokenSilently();
      const { data: searchResponse } = await searchService(value, token);

      setSearchResults(searchResponse);
      setShowResults(true);
    } catch (e) {
      setToasterData({
        title: 'Error de búsqueda',
        message: 'Error al ejecutar la búsqueda',
        type: 'ERROR',
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const resetSearch = () => {
    setQuery('');
    setSearchResults(null);
    setShowResults(false);
  };

  const debouncedSearch = useMemo(() => debounce(search, 500), [search]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    if (e.target.value !== '') {
      debouncedSearch(e.target.value);
    }
  };

  return (
    <div className="relative">
      <input
        className="w-[600px] h-[38px] rounded-md pl-10 placeholder:text-gray-500"
        placeholder="Búsqueda"
        onFocus={() => setShowResults(true)}
        onClick={() => !showResults && setShowResults(true)}
        value={query}
        onChange={handleQueryChange}
      />
      {isLoading ? (
        <Spinner
          color="text-gray-300"
          marginTop={0}
          size={6}
          extraClasses="absolute left-3 top-2"
        />
      ) : (
        <IoIosSearch className="absolute left-2 top-2 w-6 h-6 text-gray-500" />
      )}
      {showResults && query !== '' && (
        <SearchResults
          searchResults={searchResults}
          setShowResults={setShowResults}
          resetSearch={resetSearch}
        />
      )}
    </div>
  );
}
