/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useState, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { statusEnums, taxCCAAsEnums } from '../../../helpers/enums';
import { useServices } from '../../../services';
import usePagination from '../../../hooks/usePagination';
import NewTransferModal from './components/NewTransferModal';
import TransactionsTableList from '../../../components/TransactionsTableList';
import Spinner from '../../../components/Spinner';
import DateFilter from './components/DateFilter';
import FilterOptions from './components/FilterOptions';
import ActiveFilters from './components/ActiveFilters';
import EmptyTransferState from './components/EmptyTransferState';
import { checkDateRangeValid, isValidDate } from './helpers/helpers';
import NewProTransferModal from './components/NewProTransferModal';

export default function Transfers({ isPro, isMyTransfers }) {
  const [page, goNext, goPrev, setPage] = usePagination();
  const [loader, setLoader] = useState(true);
  const [transfers, setTransfers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [updater, forceUpdate] = useReducer((x) => x + 1, 0);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [minDate, setMinDate] = useState(
    searchParams.get('minDate')
      ? new Date(searchParams.get('minDate')).toISOString().substring(0, 10)
      : '',
  );
  const [maxDate, setMaxDate] = useState(
    searchParams.get('maxDate')
      ? new Date(searchParams.get('maxDate')).toISOString().substring(0, 10)
      : '',
  );
  const [minDateStatusChange, setMinDateStatusChange] = useState(
    searchParams.get('minDateStatusChange')
      ? new Date(searchParams.get('minDateStatusChange'))
          .toISOString()
          .substring(0, 10)
      : '',
  );
  const [maxDateStatusChange, setMaxDateStatusChange] = useState(
    searchParams.get('maxDateStatusChange')
      ? new Date(searchParams.get('maxDateStatusChange'))
          .toISOString()
          .substring(0, 10)
      : '',
  );
  const [initialState, setInitialState] = useState(
    searchParams.get('initialStateParam') || '',
  );
  const [finalState, setFinalState] = useState(
    searchParams.get('finalStateParam') || '',
  );
  const [dateSort, setDateSort] = useState(
    searchParams.get('dateSort') ? searchParams.get('dateSort') : 'DESC',
  );
  const [companyMembers, setCompanyMembers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [accounts, setAccounts] = useState([]);

  const [agents, setAgents] = useState([]);
  const [hosts, setHosts] = useState([]);

  const [labelOptions, setLabelOptions] = useState([]);

  const [total, setTotal] = useState(0);

  const {
    getAllTransfers,
    fetchAllLabelsByEntityType,
    fetchAllIncidentsByEntityType,
  } = useServices();

  const { user } = useAuth0();

  useEffect(() => {
    const hasIncident = searchParams.get('hasIncident')
      ? Boolean(searchParams.get('hasIncident'))
      : false;
    const isNotPaid = searchParams.get('isNotPaid')
      ? Boolean(searchParams.get('isNotPaid'))
      : null;
    const status = searchParams.get('status')
      ? Number(searchParams.get('status'))
      : null;
    const incidentTypeSort = searchParams.get('incidentType')
      ? searchParams.get('incidentType')
      : null;
    const ccaa = searchParams.get('ccaa') ? searchParams.get('ccaa') : null;
    const carOlderThan10Years = searchParams.get('carOlderThan10Years')
      ? searchParams.get('carOlderThan10Years')
      : null;
    const assignedTo = searchParams.get('assignedTo')
      ? searchParams.get('assignedTo')
      : null;
    const companyId = searchParams.get('companyId')
      ? searchParams.get('companyId')
      : null;
    const accountId = searchParams.get('accountId')
      ? searchParams.get('accountId')
      : null;
    const host = searchParams.get('host') ? searchParams.get('host') : null;
    const missingTemporaryCirculationDoc = searchParams.get(
      'missingTemporaryCirculationDoc',
    )
      ? Boolean(searchParams.get('missingTemporaryCirculationDoc'))
      : false;
    const dniAboutToExpire = searchParams.get('dniAboutToExpire')
      ? Boolean(searchParams.get('dniAboutToExpire'))
      : false;

    const hasPhysicalContract = searchParams.get('hasPhysicalContract')
      ? Boolean(searchParams.get('hasPhysicalContract'))
      : false;

    const agentId = searchParams.get('agentId')
      ? searchParams.get('agentId')
      : false;

    const itpNotLiquidated = searchParams.get('itpNotLiquidated')
      ? Boolean(searchParams.get('itpNotLiquidated'))
      : false;

    const initialStateParam = searchParams.get('initialState')
      ? searchParams.get('initialState')
      : null;

    const finalStateParam = searchParams.get('finalState')
      ? searchParams.get('finalState')
      : null;

    const labels = searchParams.getAll('labels');

    let isB2B;
    if (isPro === undefined && isMyTransfers) {
      isB2B = null;
    }
    if (isPro === undefined && !isMyTransfers) {
      isB2B = false;
    }
    if (isPro) {
      isB2B = true;
    }

    const controller = new AbortController();
    const { signal } = controller;
    const fetchTransfers = async () => {
      const { transfers: response, count } = await getAllTransfers({
        page,
        isB2B,
        hasIncident,
        status,
        isNotPaid,
        minDate,
        maxDate,
        signal,
        dateSort,
        incidentType: incidentTypeSort,
        ccaa,
        carOlderThan10Years,
        assignedEmail: isMyTransfers ? user.email : assignedTo,
        companyId,
        accountId,
        missingTemporaryCirculationDoc,
        dniAboutToExpire,
        hasPhysicalContract,
        labels,
        agentId,
        itpNotLiquidated,
        initialStateParam,
        finalStateParam,
        minDateStatusChange,
        maxDateStatusChange,
        host,
      });
      setTransfers(response);
      setTotal(count);
      setLoader(false);
    };

    fetchTransfers();

    return () => {
      controller.abort();
    };
  }, [page, isPro, updater, getAllTransfers, searchParams]);

  const handleDateSort = () => {
    if (dateSort === 'DESC') {
      setDateSort('ASC');
    }
    if (dateSort === 'ASC') {
      setDateSort('DESC');
    }
  };

  const loadLabels = async () => {
    if (labelOptions.length === 0) {
      const labels = await fetchAllLabelsByEntityType('TRANSACTION');
      setLabelOptions(labels);
    }
  };

  const specialFilters = [
    {
      key: 'isNotPaid',
      name: 'Transfers no pagadas',
      value: !Boolean(searchParams.get('isNotPaid')),
    },
    {
      key: 'carOlderThan10Years',
      name: 'Coche + 10 años',
      value: !Boolean(searchParams.get('carOlderThan10Years')),
    },
    {
      key: 'missingTemporaryCirculationDoc',
      name: 'Sin provisional',
      value: !Boolean(searchParams.get('missingTemporaryCirculationDoc')),
    },
    {
      key: 'hasPhysicalContract',
      name: 'Contrato físico',
      value: !Boolean(searchParams.get('hasPhysicalContract')),
    },
    {
      key: 'itpNotLiquidated',
      name: 'ITP no liquidado',
      value: !Boolean(searchParams.get('itpNotLiquidated')),
    },
    {
      key: 'dniAboutToExpire',
      name: 'DNI a punto de caducar',
      value: !Boolean(searchParams.get('dniAboutToExpire')),
    },
  ];

  const statusItems = statusEnums.map((status) => ({
    key: 'status',
    name: status.label,
    value: status.id,
    color: status.bgColor,
  }));

  const incidentTypeItems = [
    {
      key: 'hasIncident',
      name: 'Todas',
      value: !Boolean(searchParams.get('hasIncident')),
    },
    ...incidentTypes.map((incidentType) => ({
      key: 'incidentType',
      name: incidentType.name,
      value: incidentType.id,
    })),
  ];

  const ccaaItems = taxCCAAsEnums.map((ccaa) => ({
    key: 'ccaa',
    name: ccaa.label,
    value: ccaa.value,
  }));

  const handleLabelsFilter = (labelId) => {
    const labels = searchParams.getAll('labels');
    if (labels.includes(labelId)) {
      searchParams.delete('labels');
      labels.forEach((label) => {
        if (label !== labelId) {
          searchParams.append('labels', label);
        }
      });
    } else {
      searchParams.append('labels', labelId);
    }
    searchParams.set('page', 0);
    setPage(0);
    setSearchParams(searchParams);
  };

  const handleFilter = (key, value) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (
      (key === 'status' &&
        Boolean(searchParams.get('status')) &&
        Number(searchParams.get('status')) === value) ||
      (key === 'hasIncident' && Boolean(searchParams.get('hasIncident'))) ||
      (key === 'isNotPaid' && Boolean(searchParams.get('isNotPaid'))) ||
      (key === 'incidentType' &&
        Boolean(searchParams.get('incidentType')) &&
        searchParams.get('incidentType') === value) ||
      (key === 'ccaa' &&
        Boolean(searchParams.get('ccaa')) &&
        searchParams.get('ccaa') === value) ||
      (key === 'carOlderThan10Years' &&
        Boolean(searchParams.get('carOlderThan10Years'))) ||
      (key === 'assignedTo' &&
        Boolean(searchParams.get('assignedTo')) &&
        searchParams.get('assignedTo') === value) ||
      (key === 'companyId' &&
        Boolean(searchParams.get('companyId')) &&
        searchParams.get('companyId') === value) ||
      (key === 'accountId' &&
        Boolean(searchParams.get('accountId')) &&
        searchParams.get('accountId') === value) ||
      (key === 'missingTemporaryCirculationDoc' &&
        Boolean(searchParams.get('missingTemporaryCirculationDoc'))) ||
      (key === 'dniAboutToExpire' &&
        Boolean(searchParams.get('dniAboutToExpire'))) ||
      (key === 'hasPhysicalContract' &&
        Boolean(searchParams.get('hasPhysicalContract'))) ||
      (key === 'agentId' && Boolean(searchParams.get('agentId'))) ||
      (key === 'itpNotLiquidated' &&
        Boolean(searchParams.get('itpNotLiquidated'))) ||
      (key === 'host' &&
        Boolean(searchParams.get('host')) &&
        searchParams.get('host') === value)
    ) {
      updatedSearchParams.delete(key);
    } else {
      updatedSearchParams.set(key, value);
    }

    if (key === 'hasIncident' && Boolean(searchParams.get('incidentType'))) {
      updatedSearchParams.delete('incidentType');
    }
    if (key === 'incidentType' && Boolean(searchParams.get('hasIncident'))) {
      updatedSearchParams.delete('hasIncident');
    }

    updatedSearchParams.set('page', 0);
    setPage(0);
    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const isDateRangeValid = checkDateRangeValid(minDate, maxDate);

    if (
      minDate &&
      maxDate &&
      isValidDate(new Date(minDate)) &&
      isValidDate(new Date(maxDate)) &&
      isDateRangeValid
    ) {
      updatedSearchParams.set('minDate', minDate);
      updatedSearchParams.set('maxDate', maxDate);
      setMinDateStatusChange('');
      setMaxDateStatusChange('');
    } else {
      updatedSearchParams.delete('minDate');
      updatedSearchParams.delete('maxDate');
    }
    setSearchParams(updatedSearchParams);
  }, [minDate, maxDate]);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const isDateRangeValid = checkDateRangeValid(
      minDateStatusChange,
      maxDateStatusChange,
    );

    if (
      Boolean(minDateStatusChange) &&
      Boolean(maxDateStatusChange) &&
      Boolean(isValidDate(new Date(minDateStatusChange))) &&
      Boolean(isValidDate(new Date(maxDateStatusChange))) &&
      Boolean(isDateRangeValid) &&
      Boolean(finalState)
    ) {
      updatedSearchParams.set('minDateStatusChange', minDateStatusChange);
      updatedSearchParams.set('maxDateStatusChange', maxDateStatusChange);
      updatedSearchParams.set('finalState', finalState);
      setMinDate('');
      setMaxDate('');
    } else {
      updatedSearchParams.delete('minDateStatusChange');
      updatedSearchParams.delete('maxDateStatusChange');
      updatedSearchParams.delete('finalState');
    }

    if (
      initialState &&
      finalState &&
      minDateStatusChange &&
      maxDateStatusChange
    ) {
      updatedSearchParams.set('initialState', initialState);
    } else {
      updatedSearchParams.delete('initialState');
    }

    setSearchParams(updatedSearchParams);
  }, [minDateStatusChange, maxDateStatusChange, finalState, initialState]);

  useEffect(() => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (!['DESC', 'ASC'].includes(dateSort)) {
      setDateSort('DESC');
    } else {
      updatedSearchParams.set('dateSort', dateSort);
      setSearchParams(updatedSearchParams);
    }
  }, [dateSort]);

  useEffect(() => {
    (async () => {
      try {
        const incidents = await fetchAllIncidentsByEntityType('TRANSACTION');
        setIncidentTypes(incidents);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    })();
  }, []);

  return (
    <>
      {isPro ? (
        <NewProTransferModal
          showModal={showModal}
          setShowModal={setShowModal}
          save={forceUpdate}
        />
      ) : (
        <NewTransferModal
          showModal={showModal}
          setShowModal={setShowModal}
          save={forceUpdate}
        />
      )}

      {loader && (
        <div className="w-full h-full min-h-[500px] flex justify-center items-center">
          <Spinner color="text-blue-700" size={10} marginTop={28} />
        </div>
      )}
      {!loader && (
        <>
          <div className="flex items-center flex-wrap justify-between mt-3">
            <FilterOptions
              isPro={isPro}
              isMyTransfers={isMyTransfers}
              statusItems={statusItems}
              incidentTypeItems={incidentTypeItems}
              ccaaItems={ccaaItems}
              specialFilters={specialFilters}
              handleFilter={handleFilter}
              searchParams={searchParams}
              companyMembers={companyMembers}
              setCompanyMembers={setCompanyMembers}
              setCompanies={setCompanies}
              companies={companies}
              handleLabelsFilter={handleLabelsFilter}
              loadLabels={loadLabels}
              labelOptions={labelOptions}
              agents={agents}
              setAgents={setAgents}
              minDateStatusChange={minDateStatusChange}
              setMinDateStatusChange={setMinDateStatusChange}
              maxDateStatusChange={maxDateStatusChange}
              setMaxDateStatusChange={setMaxDateStatusChange}
              initialState={initialState}
              setInitialState={setInitialState}
              finalState={finalState}
              setFinalState={setFinalState}
              hosts={hosts}
              setHosts={setHosts}
              accounts={accounts}
              setAccounts={setAccounts}
            />
            <span className="flex flex-wrap gap-y-4 justify-end mb-4 rounded-md">
              <DateFilter
                minDate={minDate}
                setMinDate={setMinDate}
                maxDate={maxDate}
                setMaxDate={setMaxDate}
              />

              <button
                type="button"
                onClick={() => setShowModal(true)}
                className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Nueva transferencia
              </button>
            </span>
          </div>
          <ActiveFilters
            statusItems={statusItems}
            incidentTypeItems={incidentTypeItems}
            ccaaItems={ccaaItems}
            specialFilters={specialFilters}
            searchParams={searchParams}
            handleFilter={handleFilter}
            companyMembers={companyMembers}
            companies={companies}
            labelOptions={labelOptions}
            handleLabelsFilter={handleLabelsFilter}
            agents={agents}
            minDateStatusChange={minDateStatusChange}
            setMinDateStatusChange={setMinDateStatusChange}
            maxDateStatusChange={maxDateStatusChange}
            setMaxDateStatusChange={setMaxDateStatusChange}
            initialState={initialState}
            setInitialState={setInitialState}
            finalState={finalState}
            setFinalState={setFinalState}
            hosts={hosts}
            accounts={accounts}
          />
          {transfers.length > 0 ? (
            <TransactionsTableList
              data={transfers}
              page={page}
              goNext={goNext}
              goPrev={goPrev}
              handleDateSort={handleDateSort}
              dateSort={dateSort}
              total={total}
              isPro={isPro}
            />
          ) : (
            <EmptyTransferState />
          )}
        </>
      )}
    </>
  );
}
