import React from 'react';
import useDatePicker from '../../hooks/useDatePicker';
import ActiveFilters from './ActiveFilters';
import DateRangePicker from './DateRangePicker';

export interface FilterLabel {
  id: number;
  name: string;
  value: string;
}

export default function Filters() {
  const { date, setDate } = useDatePicker();

  return (
    <>
      <div className="flex gap-4 mt-3 items-center">
        <DateRangePicker date={date} setDate={setDate} />
      </div>
      <ActiveFilters date={date} setDate={setDate} />
    </>
  );
}
