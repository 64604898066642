import React from 'react';

function TrafficReportTableSkeletonLoader() {
  return (
    <div className="mt-4 flex flex-col h-full" id="loading-transfers-table">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Bastidor
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Fecha de creación
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Ver</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((transfer, personIdx) => (
                  <tr
                    key={transfer}
                    className={personIdx % 2 === 0 ? undefined : 'bg-gray-50'}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        0000ABC
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        12345678
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        14/03/2020
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        <p>Ver</p>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrafficReportTableSkeletonLoader;
