export default function DateFilter({
  minDate,
  setMinDate,
  maxDate,
  setMaxDate,
  spaceBottom = false,
}) {
  return (
    <div
      className={`mr-4 flex items-center justify-center gap-x-4 ${
        spaceBottom && 'mb-2 pb-2'
      }`}
    >
      <div className="flex items-center justify-center gap-x-2">
        <span className="text-sm">Desde</span>
        <label className="sr-only" htmlFor="after-date">
          Fecha de inicio
        </label>
        <input
          className="rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          type="date"
          name="after-date"
          id="after-date"
          value={minDate}
          onChange={(e) => setMinDate(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-center gap-x-2">
        <span className="text-sm">Hasta</span>
        <label className="sr-only" htmlFor="before-date">
          Fecha de fin
        </label>
        <input
          className="rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          type="date"
          name="before-date"
          id="before-date"
          value={maxDate}
          onChange={(e) => setMaxDate(e.target.value)}
        />
      </div>
    </div>
  );
}
