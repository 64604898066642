import AircallPhone from 'aircall-everywhere';
import { createContext, useCallback, useEffect, useState } from 'react';

export const PhoneContext = createContext();

function useAircall({
  setSearchType,
  setLastSearchType,
  setSearchQuery,
  setLastSearchTerm,
  navigate,
}) {
  const [isPhoneOpen, setIsPhoneOpen] = useState(false);
  const [isUserLogged, setIsUserLogged] = useState(false);
  const [isRinging, setIsRinging] = useState(false);

  let airCallPhone;
  let incomingCalls = [];

  useEffect(() => {
    airCallPhone = new AircallPhone({
      onLogin: () => {
        setIsUserLogged(true);
      },
      onLogout: () => {
        setIsUserLogged(false);
      },
      domToLoadPhone: '#aircalldiv',
      size: 'big',
    });

    airCallPhone?.on('incoming_call', (callEvent) => {
      incomingCalls = [...incomingCalls, callEvent];
      setIsRinging(true);
    });

    airCallPhone?.on('call_end_ringtone', (event) => {
      const callEnded = incomingCalls.find(
        (call) => call.call_id === event.call_id,
      );

      incomingCalls = incomingCalls.filter(
        (call) => call.call_id !== event.call_id,
      );

      if (event.answer_status === 'answered') {
        const correctedPhone = callEnded.from
          .replace(/\s/g, '')
          .replace('+', '');

        setSearchType('tel');
        setLastSearchType('tel');
        setSearchQuery(correctedPhone);
        setLastSearchTerm(correctedPhone);

        navigate(`/search/tel/${correctedPhone}`);
      }
    });
  }, []);

  const dial = useCallback(
    (number) => {
      if (airCallPhone) {
        airCallPhone?.send('dial_number', {
          phone_number: number,
        });
        setIsPhoneOpen(true);
      }
    },
    [airCallPhone],
  );

  return {
    isPhoneOpen,
    isUserLogged,
    isRinging,
    dial,
    setIsRinging,
    setIsPhoneOpen,
  };
}

export default useAircall;
