import stringSimilarity from 'string-similarity';

const ccaaInverseDictionary = {
  Cataluña: 'CT',
  Madrid: 'MD',
  Extremadura: 'EX',
  'País Vasco': 'PV',
  Navarra: 'NC',
  'La Rioja': 'RI',
  'Castilla y León': 'CL',
  'Castilla-La Mancha': 'CM',
  Cantabria: 'CB',
  Galicia: 'GA',
  Asturias: 'AS',
  Canarias: 'CN',
  Andalucía: 'AN',
  Aragón: 'AR',
  'Islas baleares': 'IB',
  Murcia: 'MC',
  'Comunidad Valenciana': 'VC',
  Euskadi: 'PV',
} as const;

type CcaaKey = keyof typeof ccaaInverseDictionary;
type CcaaValue = typeof ccaaInverseDictionary[CcaaKey] | null;

export default function selectMostSimilarCcaa(
  ccaaLikeString: string,
): CcaaValue {
  const bestMatch = stringSimilarity.findBestMatch(
    ccaaLikeString,
    Object.keys(ccaaInverseDictionary),
  );
  if (bestMatch.bestMatch.rating < 0.4) {
    return null;
  }
  return ccaaInverseDictionary[bestMatch.bestMatch.target as CcaaKey];
}
