import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import TransfersList from './TransfersList';
import Layout from './layout';
import QueryParamsContextProvider from './context/QueryParamsContext/QueryParamsContext';
import UserInfoContextProvider from './context/UserInfoContext/UserInfoContext';
import TransferDetails from './TransferDetails';
import { ToasterContextProvider } from './context/ToasterContext/ToasterContext';
import DomainReservationList from './DomainReservationList';
import DuplicatesList from './DuplicatesList';
import BateDetails from './BateDetails';
import RegistrationsList from './RegistrationsList';
import RegistrationDetails from './RegistrationDetails';
import CustomersList from './CustomersList';
import InvoicesList from './InvoicesList';
import VehiclesList from './VehiclesList';
import PaymentMethod from './PaymentMethod';
import TrafficReportsList from './TrafficReportsList';
import MechanicalWarrantiesList from './MechanicalWarrantiesList';

export default function SwipooPro(): JSX.Element {
  return (
    <BrowserRouter>
      <ToasterContextProvider>
        <UserInfoContextProvider>
          <QueryParamsContextProvider>
            <Layout>
              <Routes>
                <Route path="/transfers" element={<TransfersList />} />
                <Route
                  path="/transfer/:transactionCode"
                  element={<TransferDetails />}
                />
                <Route path="/bate/:bateCode" element={<BateDetails />} />
                <Route path="/registrations" element={<RegistrationsList />} />
                <Route
                  path="/registration/:registrationCode"
                  element={<RegistrationDetails />}
                />
                <Route
                  path="/domain-reservations"
                  element={<DomainReservationList />}
                />
                <Route path="/duplicates" element={<DuplicatesList />} />
                <Route
                  path="/mechanical-warranties"
                  element={<MechanicalWarrantiesList />}
                />
                <Route path="/customers" element={<CustomersList />} />
                <Route path="/invoices" element={<InvoicesList />} />
                <Route path="/vehicles" element={<VehiclesList />} />
                <Route
                  path="/traffic-reports"
                  element={<TrafficReportsList />}
                />
                <Route path="/payment-method" element={<PaymentMethod />} />
                <Route path="*" element={<TransfersList />} />
              </Routes>
            </Layout>
          </QueryParamsContextProvider>
        </UserInfoContextProvider>
      </ToasterContextProvider>
    </BrowserRouter>
  );
}
