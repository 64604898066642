import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TransfersTableProps } from '../..';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import parseDate from '../../../helpers/parseDate';
import { SHIPMENT_STATUS } from '../../../shared/transaction-status';
import { getTransferStatusLabel } from '../../../helpers/statusLabelParser';

export default function TransfersTable({ transfers }: TransfersTableProps) {
  const typeDictionary = {
    seller: 'Venta',
    buyer: 'Compra',
    intermediary: 'Intermediación',
    batecom_sale: 'Batecom - venta',
    batecom_purchase: 'Batecom - compra',
  };

  const [dateSort, setDateSort] = useState('DESC');

  const emptyLabel = { label: '', colors: '' };

  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);

  useEffect(() => {
    const dateSortParam = getSingleParam('dateSort') || 'DESC';
    setDateSort(dateSortParam);
  }, [params]);

  const shippedLabel = {
    label: 'Enviado',
    colors: 'bg-yellow-100 text-yellow-800',
  };

  const deliveredLabel = {
    label: 'Entregado',
    colors: 'bg-green-100 text-green-800',
  };

  const getShipmentStatusLabel = (status: number) => {
    if (SHIPMENT_STATUS.DELIVERED.includes(status)) {
      return deliveredLabel;
    }

    if (SHIPMENT_STATUS.SENT.includes(status)) {
      return shippedLabel;
    }

    return emptyLabel;
  };

  return (
    <div className="mt-4 flex flex-col h-full" id="transfers-table">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Tipo
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Estado del trámite
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Estado del envío
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center"
                    onClick={
                      dateSort === 'DESC'
                        ? () => setSingleParam('dateSort', 'ASC')
                        : () => setSingleParam('dateSort', 'DESC')
                    }
                  >
                    Fecha de creación{' '}
                    {dateSort === 'ASC' ? (
                      <ChevronUpIcon className="h-3" />
                    ) : (
                      <ChevronDownIcon className="h-3" />
                    )}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Ver</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {transfers.length ? (
                  transfers.map((transfer, personIdx) => (
                    <tr
                      key={transfer.tramitCode}
                      className={`${
                        personIdx % 2 === 0 ? undefined : 'bg-gray-50'
                      } hover:bg-gray-100`}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex items-center gap-2">
                        {transfer.plate}{' '}
                        {transfer.hasIncident && (
                          <ExclamationCircleIcon className="h-5 text-red-500" />
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {transfer.tramitCode}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {typeDictionary[transfer.type]}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span
                          className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                            getTransferStatusLabel(transfer.status).colors
                          }`}
                        >
                          {getTransferStatusLabel(transfer.status).label}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span
                          className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                            getShipmentStatusLabel(transfer.status).colors
                          }`}
                        >
                          {getShipmentStatusLabel(transfer.status).label}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {parseDate(transfer.createdAt)}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <Link
                          to={
                            transfer.type !== 'batecom_purchase'
                              ? `/transfer/${transfer.tramitCode}`
                              : `/bate/${transfer.tramitCode}`
                          }
                          className="text-blue-600 hover:text-blue-900"
                        >
                          Ver
                          <span className="sr-only">
                            , trámite con matrícula {transfer.plate}
                          </span>
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="h-[530px]" colSpan={6}>
                      <div className="h-full w-full flex items-center justify-center flex-col gap-5">
                        <p className="text-8xl rotate-90">: (</p>
                        <p className="w-2/3 text-center text-md">
                          Vaya, no hemos encontrado ningun trámite con estos
                          filtros, prueba a cambiarlos o, si crees que se trata
                          de un error, contacta con nosotros.
                        </p>{' '}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
