import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react';

import { queryParamsContext } from '../context/QueryParamsContext/QueryParamsContext';
import Pagination from '../components/Pagination';
import CustomersTable from './components/CustomerTable/CustomerTable';
import CustomersTableSkeletonLoader from './components/CustomerTableSkeletonLoader/CustomerTableSkeletonLoader';
import { userInfoContext } from '../context/UserInfoContext/UserInfoContext';

export interface CustomerForTable {
  id?: string;
  name: string;
  email: string;
  phone: string;
  surname: string;
  dni: string;
}

export interface CustomersTableProps {
  customers: CustomerForTable[];
}

const LIMIT = 10;

export default function CustomersList() {
  const [loadingCustomers, setLoadingCustomers] = useState(false);
  const [customers, setCustomers] = useState<CustomerForTable[]>([]);
  const { getSingleParam, setSingleParam } = useContext(queryParamsContext);
  const [page, setPage] = useState(
    getSingleParam('page') ? Number(getSingleParam('page')) : 0,
  );
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setSingleParam('page', page.toString());
  }, [page]);

  const { getAccessTokenSilently } = useAuth0();
  const { accountInfo } = useContext(userInfoContext);

  useEffect(() => {
    (async () => {
      if (!accountInfo) return;
      setLoadingCustomers(true);
      const token = await getAccessTokenSilently();

      const { data: fetchedCustomers } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/list-users/${accountInfo.id}?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setCustomers(fetchedCustomers.users);
      setTotalCount(fetchedCustomers.count);
      setTotalPages(Math.ceil(fetchedCustomers.count / LIMIT));

      setLoadingCustomers(false);
    })();
  }, [page, accountInfo.organizationId]);

  return (
    <>
      <div className="bg-gray-100 p-6 w-full">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold">Clientes</h1>
        </div>
        {loadingCustomers ? (
          <CustomersTableSkeletonLoader />
        ) : (
          <CustomersTable customers={customers} />
        )}
      </div>
      <Pagination
        limit={LIMIT}
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </>
  );
}
