import axios from 'axios';
import React, { useRef, useState, useContext } from 'react';
import { XIcon, CheckIcon } from '@heroicons/react/solid';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Button from '../../components/Button';
import { toasterContext } from '../../context/ToasterContext/ToasterContext';
import useClickOutside from '../../hooks/useClickOutside';

export default function NewTransferModal(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [buyerType, setBuyerType] = useState<'PERSON' | 'COMPANY'>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleModalOpen = () => setIsModalOpen((prev) => !prev);
  const closeModal = () => setIsModalOpen(false);

  const ref = useRef(null);
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { setToasterData } = useContext(toasterContext);

  useClickOutside(ref, closeModal);

  const handleCreateRegistration = async () => {
    const token = await getAccessTokenSilently();
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/v1/registration`,
        {
          buyerType,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-origin': 'swipoo-pro',
          },
        },
      );
      setIsLoading(false);
      setToasterData({
        title: 'Matriculación creada!',
        message: `Se creó la matriculación con código ${data.registrationCode}`,
        type: 'SUCCESS',
      });
      navigate(`/registration/${data.registrationCode}`);
    } catch (e) {
      setToasterData({
        title: 'Error',
        message: `Error al crear la matriculación, por favor intente nuevamente.`,
        type: 'ERROR',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isModalOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] z-50 w-screen h-screen flex items-center justify-center">
          <div className="w-[900px] h-fit bg-white rounded-md" ref={ref}>
            <div className="h-[65px] w-full bg-[#F9FAFB] flex items-center justify-between px-8 py-[20px] rounded-t-md">
              <p className="select-none">Nueva matriculación</p>
              <XIcon
                data-testid="close-btn"
                onClick={toggleModalOpen}
                className="text-gray-400 w-6 h-6 cursor-pointer"
              />
            </div>
            <div className="w-full">
              <div className="px-8 pt-8 pb-2 min-h-[400px]">
                <p className="mb-4 font-semibold">¿Quién compra el vehículo?</p>
                <div className="flex gap-x-4 w-full">
                  <button
                    type="button"
                    onClick={() => setBuyerType('PERSON')}
                    onKeyDown={() => setBuyerType('PERSON')}
                    className={`mt-2 border rounded-lg py-[17px] px-[25px] w-full ${
                      buyerType === 'PERSON' && 'border-2 border-blue-600'
                    }`}
                  >
                    <p className="font-semibold text-sm text-left">
                      Particular
                    </p>
                    <p className="text-gray-400 text-sm text-left">
                      El comprador es una persona física.
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => setBuyerType('COMPANY')}
                    onKeyDown={() => setBuyerType('COMPANY')}
                    className={`mt-2 border rounded-lg py-[17px] px-[25px] w-full ${
                      buyerType === 'COMPANY' && 'border-2 border-blue-600'
                    }`}
                  >
                    <p className="font-semibold text-sm text-left">Empresa</p>
                    <p className="text-gray-400 text-sm text-left">
                      El comprador es una empresa.
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center h-[65px] w-full border-t border-t-gray-200 px-[30px]">
              <div className="w-full flex justify-end">
                <Button
                  text="Crear trámite"
                  bgColor="bg-blue-600"
                  hoverBgColor="bg-blue-700"
                  textColor="white"
                  callback={handleCreateRegistration}
                  disabled={!buyerType || isLoading}
                  RightIcon={CheckIcon}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="self-center">
        <Button
          text="Nueva matriculación"
          bgColor="bg-blue-600"
          hoverBgColor="bg-blue-700"
          textColor="white"
          callback={toggleModalOpen}
          disabled={isModalOpen}
        />
      </div>
    </>
  );
}
