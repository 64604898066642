import {
  ChevronDownIcon,
  ChevronUpIcon,
  DownloadIcon,
} from '@heroicons/react/outline';
import React from 'react';
import parseDate from '../../helpers/parseDate';
import { downloadDocument, getFileName } from '../../helpers/typeformFiles';

export type Column = { label: string; key: string; file?: boolean };

type TableProps = {
  columns: Column[];
  data: {
    [key: string]: any;
  }[];
  dateSort: string;
  setSingleParam: (key: string, value: string) => void;
  view?: boolean;
};

export default function Table({
  columns,
  data,
  dateSort,
  setSingleParam,
  view = false,
}: TableProps) {
  const getCellContents = (col: Column, item: { [key: string]: any }) => {
    if (col.key === 'createdAt') {
      return parseDate(item[col.key]);
    }
    if (col?.file) {
      return (
        item[col.key] && (
          <div className="flex gap-x-2">
            <span>{getFileName(item[col.key])}</span>
            <button
              type="button"
              onClick={() =>
                downloadDocument(item[col.key], getFileName(item[col.key]))
              }
            >
              <DownloadIcon className="w-4 hover:text-indigo-500 cursor-pointer" />
            </button>
          </div>
        )
      );
    }
    return item[col.key];
  };
  return (
    <div className="mt-4 flex flex-col h-full" id="transfers-table">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle px-0.5">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  {columns.map((col, i) =>
                    col.key === 'createdAt' ? (
                      <th
                        key={col.key}
                        scope="col"
                        className="px-3 py-3.5 text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center justify-center text-center"
                        onClick={
                          dateSort === 'DESC'
                            ? () => setSingleParam('dateSort', 'ASC')
                            : () => setSingleParam('dateSort', 'DESC')
                        }
                      >
                        {col.label}
                        {dateSort === 'ASC' ? (
                          <ChevronUpIcon className="h-3" />
                        ) : (
                          <ChevronDownIcon className="h-3" />
                        )}
                      </th>
                    ) : (
                      <th
                        key={col.key}
                        scope="col"
                        className={`py-3.5 text-left px-3 text-sm font-semibold text-gray-900 uppercase ${
                          i > 0 && 'text-center'
                        }`}
                      >
                        {col.label}
                      </th>
                    ),
                  )}
                  {view && (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    <th
                      scope="col"
                      className="py-3.5 text-left px-3 text-sm font-semibold text-gray-900 uppercase"
                      aria-label="Ver"
                    />
                  )}
                </tr>
              </thead>
              <tbody className="bg-white">
                {data.length ? (
                  data.map((item, index) => (
                    <tr
                      key={item.id}
                      className={`h-[52px] hover:bg-gray-100 ${
                        index % 2 === 0 ? '' : 'bg-gray-50'
                      }`}
                    >
                      {columns.map((col, i) => (
                        <td
                          key={`${item.id}${col.key}`}
                          className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${
                            i > 0 || (i === 0 && col.key === 'createdAt')
                              ? 'text-center'
                              : ''
                          }`}
                        >
                          {getCellContents(col, item)}
                        </td>
                      ))}
                      {view && item.href && (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <a
                            href={item.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer hover:text-blue-700"
                          >
                            Ver
                          </a>
                        </td>
                      )}
                      {view && item.viewCallback && (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <button
                            type="button"
                            className="cursor-pointer hover:text-blue-700"
                            onClick={() => item.viewCallback()}
                          >
                            Ver
                          </button>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      style={{
                        height: '530px',
                      }}
                      colSpan={columns.length + 1}
                    >
                      <div className="h-full w-full flex items-center justify-center flex-col gap-5">
                        <p className="text-8xl rotate-90">: (</p>
                        <p className="w-2/3 text-center text-md">
                          Vaya, no hemos encontrado ningún item con esos
                          filtros, prueba a cambiarlos o, si crees que se trata
                          de un error, contacta con nosotros.
                        </p>{' '}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
