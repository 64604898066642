/* eslint-disable react/require-default-props */
import { XIcon } from '@heroicons/react/outline';
import React, { Dispatch, SetStateAction } from 'react';
import { DateRange } from 'react-day-picker';
import parseDate from '../../helpers/parseDate';
import { FilterLabel } from './Filters';

export type Filter = {
  filterName: string;
  paramName: string;
  filter: FilterLabel;
  setFilter: Dispatch<SetStateAction<FilterLabel | null>>;
  labels: FilterLabel[];
};

export default function ActiveFilters({
  filters,
  date,
  setDate,
}: {
  filters?: Filter[];
  date: DateRange;
  setDate: (d: DateRange) => void;
}) {
  return (
    <div className="flex gap-3 mt-3">
      {filters &&
        filters.map(({ filter, labels, setFilter }) => {
          const filterLabel = labels?.find(
            (label) => label.value === filter?.value,
          );
          return (
            filterLabel && (
              <button
                key={filter.name}
                type="button"
                onClick={() => setFilter(null)}
                className="inline-flex items-center rounded-full  text-xs font-medium bg-gray-100 gap-1 py-1 px-3 hover:bg-gray-200"
              >
                {filter.name} <XIcon className="h-2" />
              </button>
            )
          );
        })}
      {date?.from && (
        <button
          type="button"
          onClick={() => setDate(undefined)}
          className="inline-flex items-center rounded-full  text-xs font-medium bg-gray-100 gap-1 py-1 px-3 hover:bg-gray-200"
        >
          {date.from && parseDate(date.from.toISOString())}{' '}
          {date.to && `- ${parseDate(date.to.toISOString())}`}{' '}
          <XIcon className="h-2" />
        </button>
      )}
    </div>
  );
}
