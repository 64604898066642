import React from 'react';
import classNames from '../../../helpers/classNames';
import { SpecialFilterType } from '../TransfersFilters/TransfersFilters';

function SpecialFilter({
  selected,
  setSelected,
}: {
  selected: string;
  setSelected: (value: SpecialFilterType) => void;
}) {
  const specialFilterOptions: {
    label: string;
    value: SpecialFilterType;
  }[] = [
    {
      label: 'En curso',
      value: 'ongoing',
    },
    {
      label: 'Con incidencias',
      value: 'hasIncident',
    },
    {
      label: 'Finalizadas',
      value: 'completed',
    },
    {
      label: 'Anuladas',
      value: 'canceled',
    },
    {
      label: 'Todas',
      value: 'all',
    },
  ];

  return (
    <div className="mt-4">
      <div className="hidden sm:block">
        <nav className="-mb-px flex space-x-8">
          {specialFilterOptions.map((tab) => (
            <button
              key={tab.label}
              type="button"
              onClick={() => setSelected(tab.value)}
              className={classNames(
                tab.value === selected
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                'whitespace-nowrap pb-2 px-1 border-b-2 font-medium text-sm cursor-pointer',
              )}
              aria-current={tab.value === selected ? 'page' : undefined}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

export default SpecialFilter;
