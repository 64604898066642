import React from 'react';
import classNames from '../../../helpers/classNames';

function CustomersTableSkeletonLoader() {
  return (
    <div className="mt-4 flex flex-col h-full" id="loading-customers-table">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                  >
                    Nombre
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Dni
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Teléfono
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((customer, personIdx) => (
                  <tr
                    key={customer}
                    className={classNames(
                      personIdx % 2 === 0 ? undefined : 'bg-gray-50',
                      'h-[52px]',
                    )}
                  >
                    <td className="whitespace-nowrap px-4 text-sm text-gray-500">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        ----
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-4 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
                        <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                          ----
                        </span>
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-4 text-sm text-gray-500">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        {' '}
                        <span className="inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
                          ----
                        </span>
                      </span>
                    </td>
                    <td className="relative whitespace-nowrap text-right text-sm font-medium sm:pr-6">
                      <span className="bg-gray-200 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0">
                        <p>----</p>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomersTableSkeletonLoader;
