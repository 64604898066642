import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { TrafficReportsTableProps } from '../..';
import { queryParamsContext } from '../../../context/QueryParamsContext/QueryParamsContext';
import parseDate from '../../../helpers/parseDate';
import { handleViewTrafficReport } from '../../../helpers/handleViewTrafficReport';

export default function TrafficReportsTable({
  trafficReports,
}: TrafficReportsTableProps) {
  const [dateSort, setDateSort] = useState('DESC');

  const { getSingleParam, setSingleParam, params } =
    useContext(queryParamsContext);

  const { getAccessTokenSilently } = useAuth0();

  const handleClick = async (trafficReportId: string) => {
    const token = await getAccessTokenSilently();

    await handleViewTrafficReport(trafficReportId, token);
  };

  useEffect(() => {
    const dateSortParam = getSingleParam('dateSort') || 'DESC';
    setDateSort(dateSortParam);
  }, [params]);

  return (
    <div className="mt-4 flex flex-col h-full" id="transfers-table">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6">
        <div className="inline-block min-w-full py-2 align-middle md:px-6">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Bastidor
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-sm font-semibold text-gray-900 uppercase cursor-pointer flex gap-2 items-center"
                    onClick={
                      dateSort === 'DESC'
                        ? () => setSingleParam('dateSort', 'ASC')
                        : () => setSingleParam('dateSort', 'DESC')
                    }
                  >
                    Fecha de creación{' '}
                    {dateSort === 'ASC' ? (
                      <ChevronUpIcon className="h-3" />
                    ) : (
                      <ChevronDownIcon className="h-3" />
                    )}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Ver</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {trafficReports.length ? (
                  trafficReports.map((trafficReport, index) => (
                    <tr
                      key={trafficReport.id}
                      className={`${
                        index % 2 === 0 ? undefined : 'bg-gray-50'
                      } hover:bg-gray-100`}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 flex items-center gap-2">
                        {trafficReport.plate}
                      </td>
                      <td className="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900">
                        {trafficReport.frameNumber}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {parseDate(trafficReport.createdAt)}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        {trafficReport.s3Key && (
                          <button
                            type="button"
                            className="text-blue-600 hover:text-blue-900"
                            onClick={() => {
                              handleClick(trafficReport.id);
                            }}
                          >
                            Ver
                          </button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="h-[530px]" colSpan={6}>
                      <div className="h-full w-full flex items-center justify-center flex-col gap-5">
                        <p className="w-2/3 text-center text-md">
                          Parece que aún no has solicitado ningún informe de
                          tráfico.
                        </p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
