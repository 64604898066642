import React, { useState } from 'react';
import { ENTITY_TYPES } from '../../../../../helpers/enums';
import UploadFileModal from '../../../../../components/UploadFileModal';
import Document from './Document';
import ExtraFiles from '../../../../../components/ExtraFiles';

export default function DocumentsSection({
  tramit,
  tramitCode,
  forceUpdate,
  editTramit,
  documents,
}) {
  const [modalType, setModalType] = useState('');

  return (
    <>
      <UploadFileModal
        modalType={modalType}
        setModalType={setModalType}
        entityKey={tramitCode}
        editEntity={editTramit}
        forceUpdate={forceUpdate}
      />
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Documentos</dt>
        <dd className="mt-1 text-sm text-gray-900">
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            {documents.map((document) => (
              <Document
                documentKey={document.key}
                title={document.title}
                file={tramit?.[document.key]}
                setModalType={setModalType}
              />
            ))}
            <ExtraFiles tramitType={ENTITY_TYPES.BATE} entity={tramit} />
          </ul>
        </dd>
      </div>
    </>
  );
}
