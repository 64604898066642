import React from 'react';

function VehicleTableSkeletonLoader() {
  return (
    <div className="mt-4 flex flex-col h-full" id="loading-transfers-table">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 uppercase"
                  >
                    Vehículo{' '}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-center text-sm font-semibold text-gray-900 uppercase"
                  >
                    Matrícula
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 px-3 text-center text-sm font-semibold text-gray-900 uppercase"
                  >
                    Tipo
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((transfer, index) => (
                  <tr
                    key={transfer}
                    className={index % 2 === 0 ? undefined : 'bg-gray-50'}
                  >
                    <td className="whitespace-nowrap py-3.5 px-3 text-sm font-medium text-gray-900 sm:pl-6">
                      <span className="bg-gray-200 w-full h-3 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0" />
                    </td>
                    <td className="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500">
                      <span className="bg-gray-200 w-full h-3 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0" />
                    </td>
                    <td className="relative whitespace-nowrap py-3.5 px-3 text-right text-sm font-medium sm:pr-6">
                      <span className="bg-gray-200 w-full h-3 flex items-center gap-2 rounded-lg text-transparent animate-pulse relative z-0" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleTableSkeletonLoader;
