import { useEffect, useState } from 'react';
import { useServices } from '../../../../services';
import BaseModal from '../../../../components/BaseModal';
import FuzzySearch from '../../search/FuzzySearch';

const TRANSFER_TYPE_BY_USER_TYPE = {
  BUYER: 'BUYER',
  SELLER: 'SELLER',
  INTERMEDIARY: 'INTERMEDIARY',
};

const COUNTER_PART_TYPE = {
  COMPANY: 'COMPANY',
  PERSON: 'PERSON',
};

export default function NewProTransferModal({ setShowModal, showModal, save }) {
  const [userType, setUserType] = useState(TRANSFER_TYPE_BY_USER_TYPE.BUYER);
  const [counterPartType, setCounterPartType] = useState(
    COUNTER_PART_TYPE.PERSON,
  );
  const [companies, setCompanies] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [loading, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { createProTransaction, getAllCompanies } = useServices();

  useEffect(() => {
    (async () => {
      if (showModal) {
        const fetchedCompanies = await getAllCompanies();
        setCompanies(fetchedCompanies || []);
      }
    })();
  }, [getAllCompanies, showModal]);

  const resetModal = () => {
    setShowModal(false);
    setUserType(TRANSFER_TYPE_BY_USER_TYPE.BUYER);
    setLoader('');
    setErrorMessage('');
  };

  const handleSubmitPro = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const company = companies.find((comp) => comp.name === companyName);

      if (!company) {
        setErrorMessage('No se ha encontrado ninguna compañía con ese nombre');
        setCompanyName('');
        setLoader(false);
        return;
      }

      if (userType === TRANSFER_TYPE_BY_USER_TYPE.BUYER) {
        await createProTransaction({
          userType: TRANSFER_TYPE_BY_USER_TYPE.BUYER,
          counterPartType,
          intermediaryId: company.id,
          buyerType: 'COMPANY',
        });
      }

      if (userType === TRANSFER_TYPE_BY_USER_TYPE.SELLER) {
        await createProTransaction({
          userType: TRANSFER_TYPE_BY_USER_TYPE.SELLER,
          counterPartType,
          intermediaryId: company.id,
          sellerType: 'COMPANY',
        });
      }

      if (userType === TRANSFER_TYPE_BY_USER_TYPE.INTERMEDIARY) {
        await createProTransaction({
          intermediaryId: company.id,
          userType: TRANSFER_TYPE_BY_USER_TYPE.INTERMEDIARY,
          buyerType: '',
          sellerType: '',
        });
      }

      save();
      setLoader(false);
      setShowModal(false);
    } catch (error) {
      setLoader(false);
      setErrorMessage(error.message);
    }
  };

  return (
    <BaseModal isOpen={showModal} onClose={resetModal}>
      <div className="space-y-6">
        <div className="bg-light shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Crear transferencia
              </h3>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <form onSubmit={handleSubmitPro}>
                {errorMessage && (
                  <p className="text-sm text-red-500">{errorMessage}</p>
                )}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <label
                      htmlFor="transferType"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tipo de operación:
                    </label>
                    <select
                      id="transferType"
                      name="transferType"
                      required
                      className="mt-1 mb-5 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      onChange={(e) => setUserType(e.target.value)}
                    >
                      <option value="" disabled>
                        Seleccionar
                      </option>
                      <option value={TRANSFER_TYPE_BY_USER_TYPE.BUYER}>
                        Compra
                      </option>
                      <option value={TRANSFER_TYPE_BY_USER_TYPE.SELLER}>
                        Venta
                      </option>
                      <option value={TRANSFER_TYPE_BY_USER_TYPE.INTERMEDIARY}>
                        Intermediación
                      </option>
                    </select>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Empresa:
                    </label>
                    <FuzzySearch
                      values={companies.filter(
                        (company) => company.name !== null,
                      )}
                      label="name"
                      prefilledValue={companyName}
                      onSelectedChange={(name) => setCompanyName(name)}
                    />
                    {(userType === TRANSFER_TYPE_BY_USER_TYPE.BUYER ||
                      userType === TRANSFER_TYPE_BY_USER_TYPE.SELLER) && (
                      <>
                        <label
                          htmlFor="counterPartType"
                          className="block text-sm font-medium text-gray-700 mt-6"
                        >
                          {userType === TRANSFER_TYPE_BY_USER_TYPE.BUYER
                            ? '¿A quién se le compra el vehículo?'
                            : '¿A quién se le vende el vehículo?'}
                        </label>
                        <select
                          id="counterPartType"
                          name="counterPartType"
                          required
                          className="mt-1 mb-5 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          onChange={(e) => setCounterPartType(e.target.value)}
                        >
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value={COUNTER_PART_TYPE.PERSON}>
                            Persona
                          </option>
                          <option value={COUNTER_PART_TYPE.COMPANY}>
                            Empresa
                          </option>
                        </select>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    type="submit"
                    disabled={loading}
                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {loading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    {loading ? 'Creando...' : 'Crear'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}
