import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ClipboardCheckIcon } from '@heroicons/react/outline';
import { XCircleIcon, XIcon } from '@heroicons/react/solid';

import BaseModal from './BaseModal';
import { typeNameListDict, useServices } from '../services';
import { serviceTypeDict, fuelDictionary } from '../helpers/enums';

const fileTypes = [
  'dniFront',
  'dniBack',
  'sellBuyContract',
  'census',
  'technicalSheet',
  'circulationPermit',
  'trafficReport',
  'presentationProof',
  'proInvoice',
  'deeds',
  'nifFile',
  'paidItpFile',
  'paidItpProof',
];

const carTypes = [
  'frameNumber',
  'kms',
  'enrollmentDate',
  'isMoto',
  'serviceType',
  'fuel',
];

const correctDateForInput = (date) => {
  const [day, month, year] = date.split('/');
  return `${day}-${month}-${year}`.substring(0, 10);
};

export default function EditorModal({
  type,
  setType,
  id,
  save,
  plate,
  preloadedState,
  tramit,
  editTramit,
}) {
  const [text, setText] = useState('');
  const [entityType, setEntityType] = useState('user');

  // edit customer state
  const [name, setName] = useState({
    value: preloadedState?.name || '',
    hasChanged: false,
  });
  const [surname, setSurname] = useState({
    value: preloadedState?.surname || '',
    hasChanged: false,
  });
  const [email, setEmail] = useState({
    value: preloadedState?.email || '',
    hasChanged: false,
  });
  const [dni, setDni] = useState({
    value: preloadedState?.dni || '',
    hasChanged: false,
  });
  const [dniExpiryDate, setDniExpiryDate] = useState({
    value: preloadedState?.dni || '',
    hasChanged: false,
  });
  const [zipCode, setZipCode] = useState({
    value: preloadedState?.zipCode || '',
    hasChanged: false,
  });
  const [province, setProvince] = useState({
    value: preloadedState?.province || '',
    hasChanged: false,
  });
  const [birthDate, setBirthDate] = useState({
    value: preloadedState?.birthDate || '',
    hasChanged: false,
  });
  const [phone, setPhone] = useState({
    value: preloadedState?.phone || '',
    hasChanged: false,
  });
  const [customerAddress, setCustomerAddress] = useState({
    value: preloadedState?.address || '',
    hasChanged: false,
  });
  const [city, setCity] = useState({
    value: preloadedState?.city || '',
    hasChanged: false,
  });
  const [ccaa, setCcaa] = useState({
    value: preloadedState?.ccaa || '',
    hasChanged: false,
  });

  const [fiscalAddressAddress, setFiscalAddressAddress] = useState({
    value: preloadedState?.fiscalAddressAddress || '',
    hasChanged: false,
  });
  const [fiscalAddressCity, setFiscalAddressCity] = useState({
    value: preloadedState?.fiscalAddressCity || '',
    hasChanged: false,
  });
  const [fiscalAddressProvince, setFiscalAddressProvince] = useState({
    value: preloadedState?.fiscalAddressProvince || '',
    hasChanged: false,
  });
  const [fiscalAddressState, setFiscalAddressState] = useState({
    value: preloadedState?.fiscalAddressState || '',
    hasChanged: false,
  });
  const [fiscalAddressZipCode, setFiscalAddressZipCode] = useState({
    value: preloadedState?.fiscalAddressZipCode || '',
    hasChanged: false,
  });
  const [legalName, setLegalName] = useState({
    value: preloadedState?.legalName || '',
    hasChanged: false,
  });

  // car edit state
  const [carPlate, setCarPlate] = useState({
    value: preloadedState?.plate || '',
    hasChanged: false,
  });
  const [carKms, setCarKms] = useState({
    value: preloadedState?.kms || '',
    hasChanged: false,
  });
  const [frameNumber, setFrameNumber] = useState({
    value: preloadedState?.frameNumber || '',
    hasChanged: false,
  });
  const [serviceType, setServiceType] = useState({
    value: preloadedState?.serviceType || '',
    hasChanged: false,
  });
  const [fuel, setFuel] = useState({
    value: preloadedState?.fule || '',
    hasChanged: false,
  });
  const [carEnrollmentDate, setCarEnrollmentDate] = useState({
    value: preloadedState?.enrollmentDate || '',
    hasChanged: false,
  });

  const [isMoto, setIsMoto] = useState({
    value: preloadedState?.isMoto ? 'Moto' : 'Coche',
    hasChanged: false,
  });

  const [dgtFeeNumber, setDgtFeeNumber] = useState({
    value: preloadedState?.transaction?.dgtFeeNumber || '',
    hasChanged: false,
  });

  const [cetCode, setCetCode] = useState({
    value: preloadedState?.transaction?.cetCode || '',
    hasChanged: false,
  });

  // company edit state
  const [companyNif, setCompanyNif] = useState({
    value: preloadedState?.nif || '',
    hasChanged: false,
  });

  const [companyName, setCompanyName] = useState({
    value: preloadedState?.name || '',
    hasChanged: false,
  });

  const [file, setFile] = useState(null);
  const [loading, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');

  const {
    createCar,
    editUser,
    editCar,
    uploadFile,
    createUserDni,
    editCompany,
    findOrCreateCompany,
  } = useServices();

  const editUserEntry = async () => {
    try {
      if (dni.hasChanged) {
        if (dni.value.length > 0 && dni.value.includes(' ')) {
          return setErrorText('El dni no puede contener espacios en blanco');
        }
        if (!/^[a-zA-Z]?\d{6,8}[a-zA-Z0-9]$/.test(dni.value)) {
          return setErrorText(
            'El formato debe de ser 12345678X (DNI) o X1234567X(NIE)',
          );
        }
      }
      setLoader(true);
      await editUser(id, {
        ...(name.hasChanged && { name: name.value }),
        ...(surname.hasChanged && { surname: surname.value }),
        ...(email.hasChanged && { email: email.value }),
        ...(dni.hasChanged && { dni: dni.value }),
        ...(dniExpiryDate.hasChanged && { dniExpiryDate: dniExpiryDate.value }),
        ...(birthDate.hasChanged && { birthDate: birthDate.value }),
        ...(phone.hasChanged && { phone: phone.value }),
        ...(customerAddress.hasChanged && { address: customerAddress.value }),
        ...(city.hasChanged && { city: city.value }),
        ...(ccaa.hasChanged && { ccaa: ccaa.value }),
        ...(zipCode.hasChanged && { zipCode: zipCode.value }),
        ...(province.hasChanged && { province: province.value }),
      });
      setLoader(false);
      setType(null);
      return save();
    } catch (e) {
      return setError(true);
    }
  };

  const editCarEntry = async () => {
    if (
      carPlate.hasChanged &&
      (carPlate.value.includes(' ') || carPlate.value.includes('-'))
    ) {
      return setErrorText(
        'La matrícula no puede incluir guiones ni espacios en blanco',
      );
    }
    try {
      setLoader(true);

      await editCar(id, {
        ...(frameNumber.hasChanged && { frameNumber: frameNumber.value }),
        ...(serviceType.hasChanged && { serviceType: serviceType.value }),
        ...(fuel.hasChanged && { fuel: fuel.value }),
        ...(carKms.hasChanged && { kms: Number(carKms.value) }),
        ...(carEnrollmentDate.hasChanged && {
          enrollmentDate: carEnrollmentDate.value,
        }),
        ...(isMoto.hasChanged && {
          isMoto: isMoto.value === 'Moto',
        }),
      });

      setLoader(false);
      setType(null);
      setText('');
      return save();
    } catch (e) {
      setError(true);
      setLoader(false);
      return setType(null);
    }
  };

  const editCompanyEntry = async () => {
    try {
      setLoader(true);
      await editCompany(id, {
        ...(companyName.hasChanged && { name: companyName.value }),
        ...(companyNif.hasChanged && { nif: companyNif.value }),
        ...(fiscalAddressAddress.hasChanged && {
          fiscalAddressAddress: fiscalAddressAddress.value,
        }),
        ...(fiscalAddressCity.hasChanged && {
          fiscalAddressCity: fiscalAddressCity.value,
        }),
        ...(fiscalAddressProvince.hasChanged && {
          fiscalAddressProvince: fiscalAddressProvince.value,
        }),
        ...(fiscalAddressState.hasChanged && {
          fiscalAddressState: fiscalAddressState.value,
        }),
        ...(fiscalAddressZipCode.hasChanged && {
          fiscalAddressZipCode: fiscalAddressZipCode.value,
        }),
        ...(legalName.hasChanged && { legalName: legalName.value }),
      });
      setLoader(false);
      setType(null);
      setText('');
      save();
    } catch (e) {
      setLoader(false);
      setType(null);
      setError(true);
    }
  };

  const editTransferEntry = async () => {
    try {
      setLoader(true);
      if (type === 'dgtFeeNumber') {
        await editTramit(id, {
          [type]: dgtFeeNumber.value,
        });
      } else if (type === 'cetCode') {
        await editTramit(id, {
          [type]: cetCode.value,
        });
      } else {
        await editTramit(id, { [type]: text });
      }

      setLoader(false);
      setType(null);
      setText('');
      save();
    } catch (e) {
      setError(true);
    }
  };

  const createUserEntry = async () => {
    const userType = type.split('-')[1];
    try {
      if (text.includes(' ')) {
        return setErrorText('El dni no puede contener espacios en blanco');
      }
      if (!/^[a-zA-Z]?\d{6,8}[a-zA-Z0-9]$/.test(text)) {
        return setErrorText(
          'El formato debe de ser 12345678X (DNI) o X1234567X(NIE)',
        );
      }
      setErrorText('');
      setLoader(true);
      const { userId } = await createUserDni({ dni: text });
      await editTramit(id, { [`${userType}Id`]: userId });
      setLoader(false);
      setType(null);
      setText('');
      return save();
    } catch (e) {
      return setError(true);
    }
  };

  const createCompanyEntry = async () => {
    const companyType = type.split('-')[1];
    try {
      if (text.includes(' ')) {
        return setErrorText('El nif no puede contener espacios en blanco');
      }
      if (!/([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])/.test(text)) {
        return setErrorText('El NIF introducido no es válido.');
      }
      setErrorText('');
      setLoader(true);
      await findOrCreateCompany({
        nif: text,
        transactionCode: tramit.transactionCode,
        role: companyType,
      });
      setLoader(false);
      setType(null);
      setText('');
      return save();
    } catch (e) {
      return setError(true);
    }
  };

  const createCarEntry = async () => {
    try {
      setErrorText('');
      setLoader(true);
      const { id: carId } = await createCar({ plate });
      await editTramit(id, { carId });
      setLoader(false);
      setType(null);
      setText('');
      return save();
    } catch (e) {
      return setError(true);
    }
  };

  const uploadFileEntry = async () => {
    try {
      setLoader(true);
      await uploadFile(type === 'trafficReport' ? plate : id, file, type);
      setLoader(false);
      setType(null);
      setFile(null);
      save();
    } catch (e) {
      setError(true);
    }
  };

  const onChangeFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const resetEditorModal = () => {
    setName({
      value: preloadedState?.representative
        ? preloadedState?.representative?.name
        : preloadedState?.name || '',
      hasChanged: false,
    });
    setSurname({
      value: preloadedState?.representative
        ? preloadedState?.representative?.surname
        : preloadedState?.surname || '',
      hasChanged: false,
    });
    setEmail({
      value: preloadedState?.representative
        ? preloadedState?.representative?.email
        : preloadedState?.email || '',
      hasChanged: false,
    });
    setDni({
      value: preloadedState?.representative
        ? preloadedState?.representative?.dni
        : preloadedState?.dni || '',
      hasChanged: false,
    });
    setDniExpiryDate({
      value: preloadedState?.dniExpiryDate
        ? preloadedState?.representative?.dniExpiryDate
        : preloadedState?.dniExpiryDate || '',
      hasChanged: false,
    });
    setZipCode({ value: preloadedState?.zipCode || '', hasChanged: false });
    setProvince({ value: preloadedState?.province || '', hasChanged: false });
    setBirthDate({
      value: preloadedState?.representative
        ? preloadedState?.representative?.birthDate
        : preloadedState?.birthDate || '',
      hasChanged: false,
    });
    setPhone({
      value: preloadedState?.representative
        ? preloadedState?.representative?.phone
        : preloadedState?.phone || '',
      hasChanged: false,
    });
    setCustomerAddress({
      value: preloadedState?.representative
        ? preloadedState?.representative?.address
        : preloadedState?.address || '',
      hasChanged: false,
    });
    setCity({
      value: preloadedState?.representative
        ? preloadedState?.representative?.city
        : preloadedState?.city || '',
      hasChanged: false,
    });
    setCcaa({
      value: preloadedState?.representative
        ? preloadedState?.representative?.ccaa
        : preloadedState?.ccaa || '',
      hasChanged: false,
    });
    setFiscalAddressAddress({
      value: preloadedState?.fiscalAddressAddress || '',
      hasChanged: false,
    });
    setFiscalAddressCity({
      value: preloadedState?.fiscalAddressCity || '',
      hasChanged: false,
    });
    setFiscalAddressProvince({
      value: preloadedState?.fiscalAddressProvince || '',
      hasChanged: false,
    });
    setFiscalAddressState({
      value: preloadedState?.fiscalAddressState || '',
      hasChanged: false,
    });
    setFiscalAddressZipCode({
      value: preloadedState?.fiscalAddressZipCode || '',
      hasChanged: false,
    });
    setLegalName({
      value: preloadedState?.legalName || '',
      hasChanged: false,
    });
    setDgtFeeNumber({
      value: preloadedState?.transaction?.dgtFeeNumber || '',
      hasChanged: false,
    });
    setCetCode({
      value: preloadedState?.transaction?.cetCode || '',
      hasChanged: false,
    });
    setCarPlate({ value: preloadedState?.plate || '', hasChanged: false });
    setFrameNumber({
      value: preloadedState?.frameNumber || '',
      hasChanged: false,
    });
    setServiceType({
      value: preloadedState?.serviceType || '',
      hasChanged: false,
    });
    setFuel({
      value: preloadedState?.fuel || '',
      hasChanged: false,
    });
    setCarKms({ value: preloadedState?.kms || '', hasChanged: false });
    setCompanyName({ value: preloadedState?.name || '', hasChanged: false });
    setCompanyNif({
      value: preloadedState?.nif || '',
      hasChanged: false,
    });
    setCarEnrollmentDate({
      value: preloadedState?.enrollmentDate || carEnrollmentDate?.value,
      hasChanged: false,
    });
    setIsMoto({
      value: preloadedState?.isMoto || isMoto?.value,
      hasChanged: false,
    });

    setType(null);
    setText('');
    setError(false);
    setErrorText('');
    setFile(null);
    setLoader(false);
  };

  useEffect(() => {
    if (!type) resetEditorModal();
  }, [type]);

  useEffect(() => {
    resetEditorModal();
  }, [preloadedState]);

  const handleChangeText = (e) => setText(e.target.value);

  const handleButtonClick = () => {
    if (fileTypes.includes(type)) uploadFileEntry();
    else if (carTypes.includes(type)) editCarEntry();
    else if (
      [
        'companyNif',
        'legalName',
        'companyName',
        'fiscalAddressAddress',
        'fiscalAddressCity',
        'fiscalAddressProvince',
        'fiscalAddressState',
        'fiscalAddressZipCode',
      ].includes(type)
    )
      editCompanyEntry();
    else if (['dgtFeeNumber', 'cetCode'].includes(type)) editTransferEntry();
    else if (type.includes('createEntity') && entityType === 'user')
      createUserEntry();
    else if (type.includes('createEntity') && entityType === 'company')
      createCompanyEntry();
    else if (type === 'createCar') createCarEntry();
    else editUserEntry();
  };

  const renderEditableInput = () => {
    switch (type) {
      case 'name':
        return (
          <div className="flex justify-between">
            <label htmlFor="name" className="sr-only">
              Nombre
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={name.value}
              onChange={(e) =>
                setName({ value: e.target.value, hasChanged: true })
              }
              className="mr-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <label htmlFor="surname" className="sr-only">
              Apellidos
            </label>
            <input
              type="text"
              name="surname"
              id="surname"
              value={surname.value}
              onChange={(e) =>
                setSurname({ value: e.target.value, hasChanged: true })
              }
              className="ml-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'createEntity-buyer':
      case 'createEntity-seller':
      case 'createEntity-intermediary':
        return (
          <div>
            <label htmlFor="name" className="sr-only">
              Crear usuario
            </label>
            <div className="flex gap-x-4">
              <select
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                name="entity-type"
                id="entity-type-selector"
                value={entityType}
                onChange={(e) => setEntityType(e.target.value)}
              >
                <option value="user">Persona</option>
                <option value="company">Empresa</option>
              </select>
              {entityType === 'user' && (
                <input
                  type="text"
                  name="dni"
                  id="dni"
                  placeholder="DNI"
                  value={text}
                  onChange={handleChangeText}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              )}
              {entityType === 'company' && (
                <input
                  type="text"
                  name="nif"
                  id="nif"
                  placeholder="NIF"
                  value={text}
                  onChange={handleChangeText}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              )}
            </div>
          </div>
        );
      case 'isMoto':
        return (
          <div>
            <label htmlFor="isMoto" className="sr-only">
              Tipo de vehículo
            </label>
            <select
              id="isMoto"
              name="isMoto"
              onChange={(e) =>
                setIsMoto({ value: e.target.value, hasChanged: true })
              }
              defaultValue={preloadedState?.isMoto ? 'Moto' : 'Coche'}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option hidden>Seleccionar:</option>
              <option value="Coche">Coche</option>
              <option value="Moto">Moto</option>
            </select>
          </div>
        );
      case 'email':
        return (
          <div>
            <label htmlFor="email" className="sr-only">
              Correo electrónico
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={email.value}
              placeholder="email@example.com"
              onChange={(e) =>
                setEmail({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'dni':
        return (
          <div>
            <label htmlFor="dni" className="sr-only">
              DNI
            </label>
            <input
              type="text"
              name="dni"
              id="dni"
              value={dni.value}
              onChange={(e) =>
                setDni({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'zipCode':
        return (
          <div>
            <label htmlFor="dni" className="sr-only">
              Código Postal
            </label>
            <input
              type="text"
              name="zipCode"
              id="zipCode"
              value={zipCode.value}
              onChange={(e) =>
                setZipCode({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'province':
        return (
          <div>
            <label htmlFor="province" className="sr-only">
              Provincia
            </label>
            <input
              type="text"
              name="province"
              id="province"
              value={province.value}
              onChange={(e) =>
                setProvince({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'address':
        return (
          <div className="flex justify-between">
            <label htmlFor="address" className="sr-only">
              Domicilio
            </label>
            <input
              type="text"
              name="address"
              id="address"
              value={customerAddress.value}
              onChange={(e) =>
                setCustomerAddress({ value: e.target.value, hasChanged: true })
              }
              className="mr-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
            <input
              type="text"
              name="city"
              id="city"
              value={city.value}
              onChange={(e) =>
                setCity({ value: e.target.value, hasChanged: true })
              }
              className="ml-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'phone':
        return (
          <div>
            <label htmlFor="phone" className="sr-only">
              Teléfono móbil
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={phone.value}
              onChange={(e) =>
                setPhone({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'birthDate':
        return (
          <div>
            <label htmlFor="birthDate" className="sr-only">
              Fecha de nacimiento
            </label>
            <input
              type="date"
              name="birthDate"
              id="birthDate"
              value={birthDate.value}
              onChange={(e) =>
                setBirthDate({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'dniExpiryDate':
        return (
          <div>
            <label htmlFor="birthDate" className="sr-only">
              Fecha de caducidad (DNI)
            </label>
            <input
              type="date"
              name="dniExpiryDate"
              id="dniExpiryDate"
              value={dniExpiryDate.value}
              onChange={(e) =>
                setDniExpiryDate({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'createCar':
        return (
          <div>
            <label htmlFor="plate" className="sr-only">
              Matrícula
            </label>
            <input
              type="text"
              name="plate"
              id="plate"
              placeholder="Matrícula"
              value={carPlate.value}
              onChange={(e) =>
                setCarPlate({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'frameNumber':
        return (
          <div>
            <label htmlFor="frameNumber" className="sr-only">
              Número de bastidor
            </label>
            <input
              type="text"
              name="frameNumber"
              id="frameNumber"
              value={frameNumber.value}
              onChange={(e) =>
                setFrameNumber({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'serviceType':
        return (
          <div>
            <label htmlFor="serviceType" className="sr-only">
              Tipo de servicio
            </label>
            <select
              id="serviceType"
              name="serviceType"
              onChange={(e) =>
                setServiceType({ value: e.target.value, hasChanged: true })
              }
              defaultValue={serviceType.value}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option hidden>Seleccionar:</option>
              {Object.keys(serviceTypeDict).map((st) => (
                <option value={st}>
                  {st} - {serviceTypeDict[st]}
                </option>
              ))}
            </select>
          </div>
        );
      case 'fuel':
        return (
          <div>
            <label htmlFor="fuel" className="sr-only">
              Combustible
            </label>
            <select
              id="fuel"
              name="fuel"
              onChange={(e) =>
                setFuel({ value: e.target.value, hasChanged: true })
              }
              defaultValue={fuel.value}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option hidden>Seleccionar:</option>
              {Object.keys(fuelDictionary).map((f) => (
                <option value={f}>{fuelDictionary[f]}</option>
              ))}
            </select>
          </div>
        );
      case 'kms':
        return (
          <div>
            <label htmlFor="kms" className="sr-only">
              Kilometraje (Kms)
            </label>
            <input
              type="number"
              name="kms"
              id="kms"
              value={carKms.value}
              onChange={(e) =>
                setCarKms({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'companyNif':
        return (
          <div>
            <label htmlFor="companyNif" className="sr-only">
              Número NIF de la empresa
            </label>
            <input
              type="text"
              name="companyNif"
              id="companyNif"
              value={companyNif.value}
              onChange={(e) =>
                setCompanyNif({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'companyName':
        return (
          <div>
            <label htmlFor="companyName" className="sr-only">
              Nombre de la empresa
            </label>
            <input
              type="text"
              name="companyName"
              id="companyName"
              value={companyName.value}
              onChange={(e) =>
                setCompanyName({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'ccaa':
        return (
          <div>
            <label htmlFor="ccaa" className="sr-only">
              Comunidad Autónoma
            </label>
            <select
              id="ccaa"
              name="ccaa"
              onChange={(e) =>
                setCcaa({ value: e.target.value, hasChanged: true })
              }
              defaultValue={ccaa.value}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option hidden>Seleccionar:</option>
              <option value="AN">Andalucía</option>
              <option value="AR">Aragón</option>
              <option value="AS">Asturias</option>
              <option value="CN">Canarias</option>
              <option value="CB">Cantabria</option>
              <option value="CL">Castilla y León</option>
              <option value="CM">Castilla-La Mancha</option>
              <option value="CT">Cataluña</option>
              <option value="VC">Comunidad Valenciana</option>
              <option value="EX">Extremadura</option>
              <option value="GA">Galicia</option>
              <option value="IB">Islas baleares</option>
              <option value="RI">La Rioja</option>
              <option value="MD">Madrid</option>
              <option value="MC">Murcia</option>
              <option value="NC">Navarra</option>
              <option value="PV">País Vasco</option>
            </select>
          </div>
        );
      case 'dgtFeeNumber':
        return (
          <div>
            <label htmlFor="dgtFeeNumber" className="sr-only">
              Número de tasa
            </label>
            <input
              type="text"
              name="dgtFeeNumber"
              min="0"
              id="dgtFeeNumber"
              value={dgtFeeNumber.value}
              onChange={(e) =>
                setDgtFeeNumber({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'cetCode':
        return (
          <div>
            <label htmlFor="cetCode" className="sr-only">
              Número de tasa
            </label>
            <input
              type="text"
              name="cetCode"
              min="0"
              id="cetCode"
              value={cetCode.value}
              onChange={(e) =>
                setCetCode({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'dniFront':
      case 'dniBack':
      case 'census':
      case 'sellBuyContract':
      case 'circulationPermit':
      case 'technicalSheet':
      case 'trafficReport':
      case 'presentationProof':
      case 'proInvoice':
      case 'deeds':
      case 'nifFile':
      case 'paidItpFile':
      case 'paidItpProof':
        return (
          <div
            className={`px-6 pt-5 pb-6 border-2 border-gray-300 ${
              file ? 'border-solid' : 'border-dashed'
            } rounded-md`}
          >
            <div className="space-y-1 text-center justify-center">
              {file ? (
                <ClipboardCheckIcon
                  className="h-10 w-10 text-gray-400 m-auto"
                  aria-hidden="true"
                />
              ) : (
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
              <div className="text-sm text-center text-gray-600">
                {file ? (
                  <span>Archivo cargado</span>
                ) : (
                  <label
                    htmlFor="file-upload"
                    className="cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    <span>Subir archivo</span>
                    <input
                      id="file-upload"
                      type="file"
                      className="sr-only"
                      name={typeNameListDict[type]}
                      onChange={onChangeFile}
                      accept="image/*, application/pdf"
                    />
                  </label>
                )}
              </div>
              {!file && (
                <p className="text-xs text-gray-500">
                  PNG, JPG, PDF hasta 10 MB
                </p>
              )}
            </div>
          </div>
        );

      case 'legalName':
        return (
          <div>
            <label htmlFor="legalName" className="sr-only">
              Nombre legal
            </label>
            <input
              type="text"
              name="legalName"
              id="legalName"
              value={legalName.value}
              onChange={(e) =>
                setLegalName({ value: e.target.value, hasChanged: true })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'fiscalAddressAddress':
        return (
          <div>
            <label htmlFor="fiscalAddressAddress" className="sr-only">
              Calle y número
            </label>
            <input
              type="text"
              name="fiscalAddressAddress"
              id="fiscalAddressAddress"
              value={fiscalAddressAddress.value}
              onChange={(e) =>
                setFiscalAddressAddress({
                  value: e.target.value,
                  hasChanged: true,
                })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'fiscalAddressCity':
        return (
          <div>
            <label htmlFor="fiscalAddressCity" className="sr-only">
              Ciudad
            </label>
            <input
              type="text"
              name="fiscalAddressCity"
              id="fiscalAddressCity"
              value={fiscalAddressCity.value}
              onChange={(e) =>
                setFiscalAddressCity({
                  value: e.target.value,
                  hasChanged: true,
                })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'fiscalAddressProvince':
        return (
          <div>
            <label htmlFor="fiscalAddressProvince" className="sr-only">
              Provincia
            </label>
            <input
              type="text"
              name="fiscalAddressProvince"
              id="fiscalAddressProvince"
              value={fiscalAddressProvince.value}
              onChange={(e) =>
                setFiscalAddressProvince({
                  value: e.target.value,
                  hasChanged: true,
                })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );
      case 'enrollmentDate':
        return (
          <>
            <label className="sr-only" htmlFor="enrollmentDate" />
            <input
              className="rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              type="date"
              name="enrollmentDate"
              id="enrollmentDate"
              value={correctDateForInput(carEnrollmentDate.value)}
              onChange={(e) => {
                setCarEnrollmentDate({
                  value: e.target.value,
                  hasChanged: true,
                });
              }}
            />
          </>
        );

      case 'fiscalAddressState':
        return (
          <div>
            <label htmlFor="fiscalAddressState" className="sr-only">
              Comunidad Autónoma
            </label>
            <select
              id="fiscalAddressState"
              name="fiscalAddressState"
              onChange={(e) =>
                setFiscalAddressState({
                  value: e.target.value,
                  hasChanged: true,
                })
              }
              defaultValue={fiscalAddressState.value}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              <option hidden>Seleccionar:</option>
              <option value="AN">Andalucía</option>
              <option value="AR">Aragón</option>
              <option value="AS">Asturias</option>
              <option value="CN">Canarias</option>
              <option value="CB">Cantabria</option>
              <option value="CL">Castilla y León</option>
              <option value="CM">Castilla-La Mancha</option>
              <option value="CT">Cataluña</option>
              <option value="VC">Comunidad Valenciana</option>
              <option value="EX">Extremadura</option>
              <option value="GA">Galicia</option>
              <option value="IB">Islas baleares</option>
              <option value="RI">La Rioja</option>
              <option value="MD">Madrid</option>
              <option value="MC">Murcia</option>
              <option value="NC">Navarra</option>
              <option value="PV">País Vasco</option>
            </select>
          </div>
        );
      case 'fiscalAddressZipCode':
        return (
          <div>
            <label htmlFor="fiscalAddressZipCode" className="sr-only">
              Código postal
            </label>
            <input
              type="text"
              name="fiscalAddressZipCode"
              id="fiscalAddressZipCode"
              value={fiscalAddressZipCode.value}
              onChange={(e) =>
                setFiscalAddressZipCode({
                  value: e.target.value,
                  hasChanged: true,
                })
              }
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        );

      default:
        return null;
    }
  };

  const renderTypeString = () => {
    if (type === 'name') return 'Nombre y apellidos';
    if (type === 'email') return 'Correo electrónico';
    if (type === 'dni') return 'DNI';
    if (type === 'zipCode') return 'Código Postal';
    if (type === 'province') return 'Provincia';
    if (type === 'birthDate') return 'Fecha de nacimiento';
    if (type === 'phone') return 'Teléfono móbil';
    if (type === 'address') return 'Domicilio y ciudad';
    if (type === 'ccaa') return 'Comunidad Autónoma';
    if (type === 'kms') return 'Kilometraje';
    if (type === 'dniFront') return 'DNI (frontal)';
    if (type === 'dniBack') return 'DNI (reverso)';
    if (type === 'census') return 'Padrón';
    if (type === 'frameNumber') return 'Número de bastidor';
    if (type === 'sellBuyContract') return 'contrato adjuntado por cliente';
    if (type === 'circulationPermit') return 'Permiso de circulación';
    if (type === 'technicalSheet') return 'Ficha técnica';
    if (type === 'trafficReport') return 'Informe de tráfico';
    if (type === 'presentationProof') return 'Justificante de presentación';
    if (type === 'proInvoice') return 'Factura profesional';
    if (type === 'deeds') return 'Escrituras';
    if (type === 'nifFile') return 'Archivo NIF';
    if (type === 'paidItpFile') return 'Modelo 620/621';
    if (type === 'paidItpProof') return 'Justificante de Pago de ITP';
    if (type === 'createEntity-buyer') return 'Crear comprador';
    if (type === 'createEntity-seller') return 'Crear vendedor';
    if (type === 'createEntity-intermediary') return 'Crear intermediario';
    if (type === 'createCar') return 'Crear vehículo';
    if (type === 'isMoto') return 'tipo de vehículo';
    if (type === 'companyNif') return 'Número NIF';
    if (type === 'companyName') return 'Nombre de la empresa';
    if (type === 'legalName') return 'Nombre legal de la empresa';
    if (type === 'fiscalAddressAddress') return 'Calle';
    if (type === 'fiscalAddressCity') return 'Ciudad';
    if (type === 'fiscalAddressProvince') return 'Provincia';
    if (type === 'fiscalAddressState') return 'Comunidad Autónoma';
    if (type === 'fiscalAddressZipCode') return 'Código postal';
    if (type === 'dgtFeeNumber') return 'Número de tasa';
    if (type === 'enrollmentDate') return 'Fecha de matriculación';
    if (type === 'cetCode') return 'Código CET';
    if (type === 'dniExpiryDate') return 'Fecha caducidad (DNI)';
    if (type === 'serviceType') return 'Tipo de servicio';
    if (type === 'fuel') return 'Combustible';

    return null;
  };

  if (!type) return null;

  return (
    <BaseModal
      isOpen={type !== null}
      onClose={resetEditorModal}
      overflowVisible={false}
    >
      {error ? (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                Error al editar&nbsp;{renderTypeString()}
              </p>
            </div>
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  type="button"
                  onClick={resetEditorModal}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="bg-white px-6 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div
              className={
                type === 'address' ||
                type === 'name' ||
                fileTypes.includes(type)
                  ? ''
                  : 'sm:flex sm:items-start'
              }
            >
              <div className="mt-3 text-center sm:mt-0 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-md leading-6 font-medium text-gray-900"
                >
                  Editar&nbsp;{renderTypeString()}
                </Dialog.Title>
                <div className="mt-2">{renderEditableInput()}</div>
              </div>
            </div>
            {errorText && <p className="text-red-500">{errorText}</p>}
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={handleButtonClick}
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
              )}
              {loading ? 'Guardando...' : 'Guardar'}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={resetEditorModal}
            >
              Cancelar
            </button>
          </div>
        </>
      )}
    </BaseModal>
  );
}
