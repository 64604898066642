import React, { useRef, useState } from 'react';
import Button from '../../components/Button';
import { CreateTransferContextProvider } from '../../context/CreateTransferContext/CreateTransferContext';
import useClickOutside from '../../hooks/useClickOutside';
import Footer from './layout/Footer/Footer';
import Header from './layout/Header/Header';
import Stepper from './layout/Stepper/Stepper';

export default function NewTransferModal(): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModalOpen = () => setIsModalOpen((prev) => !prev);
  const closeModal = () => setIsModalOpen(false);

  const ref = useRef(null);

  useClickOutside(ref, closeModal);

  return (
    <>
      {isModalOpen && (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.4)] z-50 w-screen h-screen flex items-center justify-center">
          <CreateTransferContextProvider>
            <div className="w-[900px] h-fit bg-white rounded-md" ref={ref}>
              <Header toggleModalOpen={toggleModalOpen} />
              <Stepper />
              <Footer toggleModalOpen={toggleModalOpen} />
            </div>
          </CreateTransferContextProvider>
        </div>
      )}
      <div className="self-center">
        <Button
          text="Nueva compraventa"
          bgColor="bg-blue-600"
          hoverBgColor="bg-blue-700"
          textColor="white"
          callback={toggleModalOpen}
          disabled={isModalOpen}
        />
      </div>
    </>
  );
}
