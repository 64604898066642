/* eslint-disable react/jsx-no-constructed-context-values */
// eslint-disable-next-line import/no-extraneous-dependencies
import 'tailwindcss/tailwind.css';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, {
  Fragment,
  useState,
  useReducer,
  useEffect,
  createContext,
} from 'react';
import { Dialog, Transition, Menu } from '@headlessui/react';
import {
  FolderIcon,
  FolderAddIcon,
  HomeIcon,
  XIcon,
  MenuAlt2Icon,
  DocumentTextIcon,
  ChevronDownIcon,
  ReceiptTaxIcon,
  GlobeIcon,
  FolderOpenIcon,
  TerminalIcon,
  InboxIcon,
  UserIcon,
} from '@heroicons/react/outline';
import { CgEuro } from 'react-icons/cg';
import { SearchIcon } from '@heroicons/react/solid';

import { classNames } from '../helpers';
import useAircall from '../hooks/useAircall';

export const PhoneContext = createContext();

function Layout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchType, setSearchType] = useState('n');
  const [lastSearchTerm, setLastSearchTerm] = useState('');
  const [lastSearchType, setLastSearchType] = useState('');
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const navigate = useNavigate();
  const location = useLocation();

  const { logout, user } = useAuth0();

  const { dial } = useAircall({
    setSearchType,
    setLastSearchType,
    setSearchQuery,
    setLastSearchTerm,
    navigate,
  });

  const navigation = [
    {
      name: 'Dashboard',
      href: '/',
      icon: HomeIcon,
      current: location.pathname === '/',
    },
    {
      name: 'Transferencias',
      href: '/transfers?page=0',
      icon: FolderIcon,
      current: location.pathname.includes('/transfers'),
    },
    {
      name: 'Transferencias Pro',
      href: '/pro-transfers?page=0',
      icon: FolderAddIcon,
      current: location.pathname.includes('/pro'),
    },
    {
      name: 'Batecoms',
      href: '/batecoms?page=0',
      icon: FolderIcon,
      current: location.pathname.includes('/batecoms'),
    },
    {
      name: 'Matriculaciones',
      href: '/registrations?page=0',
      icon: FolderIcon,
      current: location.pathname.includes('/registrations'),
    },
    {
      name: 'Mis Transferencias',
      href: '/my-transfers?page=0',
      icon: FolderOpenIcon,
      current: location.pathname.includes('/my-transfers'),
    },
    {
      name: 'Cuentas',
      href: '/accounts?page=0',
      icon: UserIcon,
      current: location.pathname.includes('/accounts'),
    },
    {
      name: 'Gestores',
      href: '/agents',
      icon: InboxIcon,
      current: location.pathname.includes('/agents'),
    },
    {
      name: 'Informes',
      href: '/reports',
      icon: DocumentTextIcon,
      current: location.pathname === '/reports',
    },
    {
      name: 'Informes Pro',
      href: '/reports-pro',
      icon: DocumentTextIcon,
      current: location.pathname === '/reports-pro',
    },
    {
      name: 'Tasas',
      href: '/dgt-fees',
      icon: ReceiptTaxIcon,
      current: location.pathname === '/dgt-fees',
    },
    {
      name: 'Distintivos Medioambientales',
      href: '/environmental-badges',
      icon: GlobeIcon,
      hidePro: true,
      current: location.pathname === '/environmental-badges',
    },
    {
      name: 'Planes de Pago',
      href: '/price-plans',
      icon: CgEuro,
      current: location.pathname === '/price-plans',
    },
    {
      name: 'Herramientas',
      href: '/tools',
      icon: TerminalIcon,
      current: location.pathname === '/tools',
    },
  ];

  const typesDictionary = {
    n: 'Nombre',
    tel: 'Teléfono',
    dni: 'DNI',
    e: 'Email',
    p: 'Matrícula',
    c: 'Código de Referencia',
    f: 'Nº de tasa de la DGT',
    cet: 'Código CET',
    nif: 'NIF de empresa',
    ne: 'Nombre de empresa',
    itpL: 'ITP Liquidado',
    otn: 'OTN',
    an: 'Nombre de cuenta',
  };

  const selectTitle = () => {
    switch (location.pathname) {
      case '/transfers':
        return 'Transferencias';
      case '/pro-transfers':
        return 'Transferencias Pro';
      case '/batecoms':
        return 'Batecoms';
      case '/tools':
        return 'Herramientas';
      case '/agents':
        return 'Gestores';
      case '/accounts':
        return 'Cuentas';
      case '/reports':
        return 'Informes';
      case '/reports-pro':
        return 'Informes Pro';
      case '/dgt-fees':
        return 'Tasas';
      case '/environmental-badges':
        return 'Distintivos Medioambientales';
      case '/my-transfers':
        return 'Mis Transferencias';
      case '/registrations':
        return 'Matriculaciones';
      case '/price-plans':
        return 'Planes de Pago';
      case '/':
        return 'Dashboard';
      default:
        if (location.pathname.includes('/search/')) {
          if (!lastSearchTerm || !lastSearchType) {
            const refreshedSearchType = location.pathname.split('/')[2];
            const refreshedSearchTerm = location.pathname.split('/')[3];
            return `Resultados para ${typesDictionary[refreshedSearchType]}: ${refreshedSearchTerm}`;
          }
          return `Resultados para ${typesDictionary[lastSearchType]}: ${lastSearchTerm}`;
        }
        return null;
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    forceUpdate();
    navigate(`/search/${searchType}/${searchQuery}`);
    setLastSearchType(searchType);
    setLastSearchTerm(searchQuery);
  };

  useEffect(() => {
    if (searchQuery) {
      navigate(`/search/${searchType}/${searchQuery}`);
      setLastSearchType(searchType);
      setLastSearchTerm(searchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType]);

  const shouldHaveMargin = ![
    'Informes',
    'Transferencias',
    'Transferencias Pro',
    'Cuentas',
    'Tasas',
  ].includes(selectTitle());

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600/75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Cerrar sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <Link to="/">
                <div className="shrink-0 flex items-center px-4">
                  <img
                    src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-icon.svg"
                    alt="swipoo logo"
                    width="35"
                    height="35"
                  />
                  <span className="ml-4">
                    <img
                      src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-name.svg"
                      alt="swipoo name"
                      width="75"
                      height="70"
                    />
                  </span>
                </div>
              </Link>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      to={item.href}
                      key={item.name}
                      className={classNames(
                        item.current
                          ? 'bg-gray-900 text-white'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.current
                            ? 'text-gray-300'
                            : 'text-gray-400 group-hover:text-gray-300',
                          'mr-4 shrink-0 h-6 w-6',
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <Link to="/">
            <div className="flex items-center h-16 shrink-0 px-4 bg-gray-900">
              <img
                src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-icon.svg"
                alt="swipoo logo"
                width="35"
                height="35"
              />
              <span className="ml-4">
                <img
                  src="https://s3.eu-west-1.amazonaws.com/assets.swipoo.com/swipoo-white-name.svg"
                  alt="swipoo name"
                  width="75"
                  height="65"
                />
              </span>
            </div>
          </Link>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 space-y-1">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
                  )}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? 'text-gray-300'
                        : 'text-gray-400 group-hover:text-gray-300',
                      'mr-3 shrink-0 h-6 w-6',
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col">
        <div className="sticky top-0 z-10 shrink-0 flex h-16 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Abrir sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              <form className="w-full flex md:ml-0" onSubmit={handleSearch}>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600 flex">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <label htmlFor="search-type" className="sr-only">
                    Tipo de búsqueda
                  </label>
                  <select
                    id="search-type"
                    className="border-none outline-none focus:ring-0 ml-4"
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                  >
                    <option value="n">Nombre</option>
                    <option value="tel">Teléfono</option>
                    <option value="dni">DNI</option>
                    <option value="e">Email</option>
                    <option value="p">Matrícula</option>
                    <option value="c">Código de referencia</option>
                    <option value="f">Nº Tasa DGT</option>
                    <option value="cet">Código CET</option>
                    <option value="nif">NIF de empresa</option>
                    <option value="ne">Nombre de empresa</option>
                    <option value="itpL">Itp Liquidado</option>
                    <option value="otn">OTN</option>
                    <option value="an">Nombre de cuenta</option>
                  </select>
                  <label htmlFor="search-field" className="sr-only">
                    Búsqueda
                  </label>
                  <input
                    id="search-field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Search"
                    type="search"
                    name="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="ml-4 flex items-center md:ml-6">
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50 select-none">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user.picture}
                    alt={user.name}
                  />
                  <ChevronDownIcon
                    className="hidden shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'flex flex-col px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        <button
                          type="button"
                          className="w-full"
                          onClick={() =>
                            logout({
                              returnTo: window.location.origin,
                            })
                          }
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
        <PhoneContext.Provider value={{ dial }}>
          <main className="flex-1">
            <div className="py-6">
              <div
                className={`max-w-7xl mx-auto px-4 sm:px-6 md:px-8 ${
                  shouldHaveMargin && 'mb-4'
                }`}
              >
                <h1 className="text-2xl font-semibold text-gray-900 select-none">
                  {selectTitle()}
                </h1>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {children}
              </div>
            </div>
          </main>
        </PhoneContext.Provider>
      </div>
    </div>
  );
}

export default Layout;
